import React, { useState, useEffect } from 'react'
import 'dayjs/locale/es';
import { useAuthStatus } from '../../hooks/useAuthStatus';
import BlockScreen from '../blockScreen/blockScreen';
import { useNavigate } from 'react-router-dom';
import Loader from '../../elements/loader';
import { Vehicle } from '../../types/fields';
import performApiRequest from '../../utils/performApiRequest';
import { useDataForEndpoint } from '../../context/dataProvider';
import { useAccessPermissions } from '../../hooks/useAccessPermission';

type VehicleType = {
    id: number;
    category_id: number;
    type_name: string;
};

type VehicleStatus = {
    id: number;
    status_name: string;
};

export default function ShowAllVehicles () {
    const isAuthenticated = useAuthStatus();
    const vehicleTypes = useDataForEndpoint<VehicleType[]>('vehicle_types');
    const vehicleStatuses = useDataForEndpoint<VehicleStatus[]>('vehicle_statuses');
    const [vehicles, setVehicles] = useState<Vehicle[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const { accessPermissions } = useAccessPermissions();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            const allData = [
                vehicleTypes,
                vehicleStatuses,
            ];
              
            const isAnyDataUndefined = allData.some(data => data === undefined);
          
            setIsLoading(isAnyDataUndefined);
        }
    }, [vehicleTypes, vehicleStatuses, isAuthenticated]);
    
    
    useEffect(() => {
        if (isAuthenticated) {
            const fetchVehicles = async () => {
                try {
                    const endpoint = `/v1/vehicleController/getAllVehicles`;
                    const requestOptions = {
                        method: 'GET'
                    };
                    const response = await performApiRequest(endpoint, requestOptions);
                    setVehicles(response.data);
                    setIsLoading(false);
                } catch (error) {
                    console.error('There was an error fetching the vehicles:', error);
                }
            };
    
            if (isAuthenticated) {
                fetchVehicles();
            }
        }
    }, [isAuthenticated]);

    const handleClick = (vehicle_id: number) => {
        navigate(`/vehicleDetails/${vehicle_id}`);
    }

    if (!isAuthenticated) {
        return <BlockScreen/>
    }

    if (isLoading) {
        return <Loader/>
    }

    return (
        <div className="flex justify-center items-center p-4">
        <div className="overflow-x-auto rounded">
        {(accessPermissions.travelManagementSystem.vehicleAdminPermissions === true) && (
            <div className="flex justify-end mb-10">
                <button className="text-white font-bold py-2 px-4 rounded-lg bg-customBlue hover:bg-blue-800" onClick={() => navigate('/addVehicle')}>
                    Agregar vehículo
                </button>
            </div> 
        )}

        <h2 className="py-3 text-gray-700 text-md font-semibold mb-2">Haz clic sobre una patente para ver detalles del vehículo.</h2>

        <table className="min-w-full bg-sectionBarBackgroundColor shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <thead className="bg-sectionBarBackgroundColor">
            <tr>
              <th className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">Patente</th>
              <th className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">Tipo de vehículo</th>
              <th className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">Marca</th>
              <th className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">Modelo</th>
              <th className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">Estado</th>
            </tr>
          </thead>
          <tbody className="text-gray-700">
            {vehicles.map((vehicle) => (
              <tr key={vehicle.id} className="border-b">
                <td className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">
                  <button className="text-customBlue hover:text-blue-800" onClick={() => handleClick(vehicle.id)}>
                    {vehicle.vehicle_plate}
                  </button>
                </td>
                <td className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">{vehicle.vehicle_type.type_name}</td>
                <td className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">{vehicle.brand}</td>
                <td className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">{vehicle.model}</td>
                <td className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">{vehicle.vehicle_status.status_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </div>
  )
}
