import React, { useState, useEffect, ChangeEvent  } from 'react';
import { useAuthStatus } from '../../hooks/useAuthStatus';
import { useDataForEndpoint } from '../../context/dataProvider';
import Loader from '../../elements/loader';
import Layout from '../../components/layOutWrapper/layOutWrapper';
import VerticalLogo from "./../../assets/images/VerticalLogo.png"
import { SystemRole } from '../../types/fields';
import BlockScreen from '../../components/blockScreen/blockScreen';
import PermissionsAdministratorPanel from './permissionAdministratorPanel';

interface LeftDrawerProps {
  roles: SystemRole[];
  filter: string;
  onFilterChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSelectRole: (role: SystemRole) => void;
  selectedRoleId?: string;
}

interface MainContentProps {
  roleId: string;
  roleSystem: string;
  roleName: string;
  roleDescription: string;
}

export default function PermissionsAdministratorView () {
    return (
        <Layout 
            children={<GeneralAdministratorPanel/>} 
            sectionTitle="Administra los permisos de los usuarios"
        />
    )
}

function GeneralAdministratorPanel() {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState<SystemRole | null>(null);
  const isAuthenticated = useAuthStatus();
  const [isLoading, setIsLoading] = useState(false);
  const systemRoles = useDataForEndpoint<SystemRole[]>("system_roles");
  const [filteredRoles, setFilteredRoles] = useState<SystemRole[]>(systemRoles);

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    setSearchTerm(value);
    if (systemRoles) {
        setFilteredRoles(systemRoles.filter((role: SystemRole) =>
          role.role_name.toLowerCase().includes(value.toLowerCase())
        ));
    }
  };

  useEffect(() => {
    if (systemRoles) {
        setFilteredRoles(systemRoles.filter((role: any) =>
            role.role_name.toLowerCase().includes(searchTerm.toLowerCase())
        ));
    }
  }, [systemRoles, searchTerm]);

  useEffect(() => {
    const allData = [
      systemRoles,
    ];
    
    if (isAuthenticated) {
        const isAnyDataUndefined = allData.some(data => data === undefined);
        setIsLoading(isAnyDataUndefined);
    }
  }, [systemRoles, isAuthenticated]);

  if (isLoading) {
    return <Loader/>
  } else if (!isAuthenticated) {
    return <BlockScreen/>
  }

  return (
    <div className="flex h-screen">
      <LeftDrawer 
        roles={filteredRoles}
        filter={searchTerm}
        onFilterChange={handleFilterChange}
        onSelectRole={(role: SystemRole) => setSelectedRole(role)}
        selectedRoleId={String(selectedRole?.id) ?? '0'}
      />
      <div className="flex-1 flex flex-col">
        <div className="flex-1 overflow-auto mt-5 ml-3">
          <MainContent roleId={String(selectedRole?.id) ?? '0'} roleSystem={selectedRole?.system_name ?? ''} roleName={selectedRole?.role_name ?? ''} roleDescription={selectedRole?.description ?? ''}/>
        </div>
      </div>
    </div>
  );
};

const LeftDrawer: React.FC<LeftDrawerProps> = ({ roles, filter, onFilterChange, onSelectRole, selectedRoleId }) => {
  if (!roles) {
    return <Loader message={"Cargando unidades..."}/>;
  }

  return (
    <div className="w-64 h-full bg-gray-100 p-4 flex flex-col overflow-y-auto">
      <h3 className="mb-5 font-bold text-center">Selecciona un permiso</h3>
      <input 
        type="text"
        placeholder="Buscar permiso..."
        value={filter}
        onChange={onFilterChange}
        className="mb-4 p-2 border-2 border-gray-300"
      />
      {roles.map((role: any) => (
        <button
          key={role.id}
          onClick={() => onSelectRole(role)}
          className={`mb-2 p-2 border-2 border-gray-300 text-left ${
            Number(role.id) === Number(selectedRoleId) ? 'bg-blue-500 text-white font-bold' : 'bg-white'
          }`}
        >
          {role.system_name} - { role.role_name }
        </button>
      ))}
    </div>
  )
};

const MainContent: React.FC<MainContentProps> = ({ roleId, roleSystem, roleName, roleDescription }) => {

    if (roleId === undefined || roleId === null || roleId === '0' || roleId === 'undefined') {
        return (
        <div className="flex flex-col justify-center w-full h-screen">
            <div className="text-center mb-5 font-bold text-md items-center">
                Selecciona un permiso para comenzar.
            </div>
            <div className="flex justify-center items-center">
            <img src={VerticalLogo} alt="Logo vertical de Dictuc S.A." className="object-scale-down sm:h-24 md:h-60 xl:h-75 sm:w-24 md:w-60 xl:w-75" />
            </div>
        </div>
        )
    }
    return <PermissionsAdministratorPanel selectedRoleId={roleId} selectedRoleSystem={roleSystem} selectedRoleName={roleName} selectedRoleDescription={roleDescription}/>;
};

