import React, { useState } from 'react';
import performApiRequest from '../utils/performApiRequest';
import { API_ENDPOINTS } from '../utils/endpoints';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DownloadButton from './downloadButton';
import showExactDate from './../utils/showExactDate'
import { useToast } from '../context/useToast';

interface FileModalProps {
    isOpen: boolean;
    onClose: () => void;
    openFileUploadModalInParent: () => void;
    onEditFile: (file: any) => void;
    uploadedFiles: {[key: string]: any};
}

const FilesModal: React.FC<FileModalProps> = ({ isOpen, onClose, openFileUploadModalInParent, uploadedFiles, onEditFile }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const file = uploadedFiles[activeIndex];
    const { showSuccessToast } = useToast();

    const goToNextFile = () => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % uploadedFiles.length);
    };
    
    const goToPrevFile = () => {
        setActiveIndex((prevIndex) => (prevIndex - 1 + uploadedFiles.length) % uploadedFiles.length);
    };

    const deleteFile = async (file_id: number) => {
        if (window.confirm("Presiona Aceptar para confirmar la eliminación del archivo")) {
            const endpoint = API_ENDPOINTS.deleteFileFromTravelAllowanceRequest;
            
            const requestOptions = {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({file_id})
            }

            const response = await performApiRequest(endpoint, requestOptions);
            if (response.status === 204) {
                showSuccessToast('Archivo eliminado exitosamente.')
            }
        }
    }


    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-content mt-10">
                <div className="flex flex-col md:flex-row md:justify-between">
                    <button onClick={openFileUploadModalInParent} className="w-10/12 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2 mb-2">
                        Agregar un archivo
                    </button>
                    <button onClick={onClose} className="w-10/12 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mb-2">
                        Cerrar
                    </button>
                </div>
                <h2 className="text-gray-700 text-lg font-bold mb-4 text-center">Archivos subidos</h2>
                { uploadedFiles.length > 1 && (
                    <div className="text-center text-md"> Utiliza las flechas para desplazarte entre los archivos. </div>
                )}
                <div className="flex flex-col p-4 bg-white shadow rounded-lg">
                    <div className="relative flex items-center">
                        {uploadedFiles.length > 1 && (
                            <button onClick={goToPrevFile}>{<KeyboardArrowLeftIcon fontSize="large"/>}</button>
                        )}
                        {(uploadedFiles.length > 0) ? (
                            <div className="flex flex-col lg:flex-row -mx-4 mb-4">
                            <div className="w-full lg:w-1/2 px-4 flex justify-center items-start">
                                <div>
                                    <h2 className="text-gray-700 text-lg text-center font-bold mb-4">Archivo</h2>
                                    <img src={file.file_url} alt={`Archivo ${file.id}`} className="max-w-full h-auto"/>
                                </div>
                                <DownloadButton fileName={file.file_name}/>
                            </div>
                            <div className="w-full lg:w-1/2 px-4 flex flex-col justify-between">
                                <div>
                                    <h2 className="text-gray-700 text-lg font-bold mb-4">Datos del archivo</h2>
                                    <p><strong>Número de documento:</strong> {file.document_number}</p>
                                    <p><strong>RUT de la empresa:</strong> {file.business_rut}</p>
                                    <p><strong>Fecha del documento:</strong> {showExactDate(file.expense_date)}</p>
                                    <p><strong>Monto total:</strong> {file.total_amount}</p>
                                    {file.commentary ? 
                                        (<p><strong> Descripción: </strong> {file.commentary}</p>) 
                                        : 
                                        ( null )
                                    }
                                </div>
                                <div className="grid grid-cols-2 md:grid-cols-1">
                                    <button className="bg-black text-white px-2 py-1 rounded-md mb-5" onClick={() => onEditFile(file)}> Editar este documento </button>
                                    <button className="bg-red-600 hover:bg-red-800 text-white px-2 py-1 rounded-md" onClick={() => deleteFile(file.id)}> Eliminar este documento </button>
                                </div>
                            </div>
                        </div>
                        ) : (
                            <div className="text-center font-bold"> No ha subido archivos. </div>
                        )}
                        {uploadedFiles.length > 1 && (
                            <button onClick={goToNextFile}>{<KeyboardArrowRightIcon fontSize="large"/>}</button>
                        )}
                    </div>
                    </div>
                </div>
        </div>
    );
};

export default FilesModal;