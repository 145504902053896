import React, { ReactNode } from 'react';
import { Header } from './header';
import { Footer } from './footer';
import SectionBar from '../../elements/sectionBar';

interface LayoutProps {
  children: ReactNode;
  sectionTitle: string;
}

const Layout: React.FC<LayoutProps> = ({ children, sectionTitle }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <header className="relative z-10">
        <Header/>
      </header>
      <div className="sm:px-20 mb-10 flex-grow relative z-0">
      {sectionTitle ? <SectionBar title={sectionTitle}/> : null}
      <main className="flex flex-col flex-grow">
        {children? children : null}
      </main>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;