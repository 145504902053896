export default function ConvertDateToFullTime(dateString: string) {
    // Create a new Date object using the dateString
    const date = new Date(dateString);
  
    // Get the day, month, and year from the date object
    const day = String(date.getDate()).padStart(2, '0'); // Pad with zero if necessary
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 because months are 0-indexed
    const year = date.getFullYear();

    // Get the hours and minutes
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Combine the parts into the desired format including time
    return `${day}/${month}/${year} ${hours}:${minutes}`;
}