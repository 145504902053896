interface DigitInputProps {
    value: string;
    onChange: (value: string) => void;
}

const ChangeCurrencyRateInput: React.FC<DigitInputProps> = ({ value, onChange }) => {
    return (
      <input
        type="text"
        value={value}
        onChange={(e) => {
          // Updated to allow floating point values
          const onlyNumsAndDot = e.target.value
            .replace(/[^0-9.]+/g, '')
            .replace(/(\..*?)\..*/g, '$1');
          onChange(onlyNumsAndDot);
        }}
        className="w-full"
      />
    );
}
  
export default ChangeCurrencyRateInput;