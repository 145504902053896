import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useMsal } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import msalConfig from './msalConfig';
import axios from 'axios';
import { AuthContextType } from '../../types/auth';

const falseAccessPermissions = {
	travelManagementSystem: {
		adminPermissions: false,
		observerPermissions: false,
		accountingPermissions: false,
		vehicleAdminPermissions: false,
		treasuryPermissions: false,
		informerPermissions: false,
		monitorPermissions: false,
    regularPermissions: true,
	},
	expenseReportSystem: {
		adminPermissions: false,
		observerPermissions: false,
		submitterPermissions: false,
		approverPermissions: false,
		monitorPermissions: false,
		accountingPermissions: false,
		treasuryPermissions: false,
	},
	adminPermissions: false
}

export const msalInstance = new PublicClientApplication(msalConfig);
const AuthContext = createContext<AuthContextType>({ error: null, accessPermissions: falseAccessPermissions });
await msalInstance.initialize();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { accounts } = useMsal();
  const [error, setError] = useState<Error | null>(null);
  const [accessPermissions, setAccessPermissions] = useState(falseAccessPermissions);

  const fetchPermissions = async (idToken: string) => {
    try {
      const accessObject = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/v1/generalAccessController/`, {
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      });
      setAccessPermissions(accessObject.data.accessPermissions);
    } catch (err) {
      console.error('Failed to fetch user data:', err);
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_USE_MOCK_AUTH === 'false') {
      if (accounts.length > 0) {
        msalInstance.acquireTokenSilent({
          scopes: ["User.Read"],
          account: accounts[0],
        })
        .then(response => {
          fetchPermissions(response.idToken);
        })
        .catch(err => {
            console.error(err);
            setError(err);
            // Check if the error is an InteractionRequiredAuthError
            if (err && err.errorCode && (err.errorCode === 'interaction_required' || err.errorCode === 'login_required' || err.errorCode === 'consent_required')) {
              // Initiate interactive sign-in
              msalInstance.loginRedirect({
                scopes: ["User.Read"]
              }).catch(error => {
                // Handle errors from the loginRedirect call
                console.error("Failed to login:", error);
              });
            }
        });
      }
     } else {
      fetchPermissions("mockApiToken8");
    }
  }, [accounts]);

  return (
    <AuthContext.Provider value={{ error, accessPermissions }}>
      {children}
    </AuthContext.Provider>
  );
};
