import { createTheme } from '@mui/material/styles';
import { esES } from '@mui/x-date-pickers/locales';

const theme = createTheme({
  palette: {
    primary: { main: '#1976d2' },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          backgroundColor: 'white',
        },
        root: {
          '& .MuiInputBase-input': {
            padding: '0.75rem', // Adjust padding to match py-2 and px-3
            color: '#4a5568', // Adjust text color to text-gray-700
          },
          '&.Mui-focused .MuiInputBase-input': {
            borderColor: '#1976d2', // Adjust focus border color
            boxShadow: '0 0 0 2px rgba(25, 118, 210, 0.5)', // Adjust focus shadow outline
          },
        },
      },
    },
  },
}, esES);

export default theme;