import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import 'dayjs/locale/es';
import { useAuthStatus } from '../../hooks/useAuthStatus';
import BlockScreen from '../blockScreen/blockScreen';
import { useNavigate } from 'react-router-dom';
import Loader from '../../elements/loader';
import ConvertDateToDayMonthYear from '../../utils/convertDateToDayMonthYear';
import 'react-toastify/dist/ReactToastify.css';
import performApiRequest from '../../utils/performApiRequest';
import NoPhotoImage from './../../assets/images/NoPhoto.jpg';
import { Vehicle } from '../../types/fields';
import VehicleTracker from '../../utils/vehicleTracker'

export default function ShowParticularVehicle () {
    const isAuthenticated = useAuthStatus();
    const [vehicle, setVehicle] = useState<Vehicle>();
    const [vehicleImage, setVehicleImage] = useState<string>("");
    const [vehicleRecords, setVehicleRecords] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();
    const navigate = useNavigate();    
    
    useEffect(() => {
        if (isAuthenticated) {
            const fetchVehicle = async () => {
                try {
                    const endpoint = `/v1/vehicleController/getParticularVehicle/${id}`;
                    const vehicleRecordEndpoint = `/v1/vehicularResourcesMonitor/getVehicleRecords/${id}`;
                    const requestOptions = {
                        method: 'GET'
                    };
                    const response = await performApiRequest(endpoint, requestOptions);
                    const vehicleRecordResponse = await performApiRequest(vehicleRecordEndpoint, requestOptions);
                    setVehicle(response.data.vehicle);
                    setVehicleImage(response.data.image_url);
                    setVehicleRecords(vehicleRecordResponse.data);
                } catch (error) {
                    console.error('There was an error fetching the vehicle:', error);
                }
            };
    
            if (isAuthenticated) {
                fetchVehicle();
                setIsLoading(false);
            }
        }
    }, [isAuthenticated, id]);

    const handleEdit = (vehicle: Vehicle) => {
        navigate('/editVehicle', { state: { vehicleData: vehicle } });
    };

    if (!isAuthenticated) {
        return <BlockScreen/>
    }

    if (isLoading) {
        return <Loader/>
    }

    if (!vehicle) {
        return <Loader/>
    }

    return (
        <div>
            <div className="flex flex-col md:flex-row p-4 bg-white shadow rounded-lg">
            <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="text-xl font-bold">{vehicle.brand} {vehicle.model}</div>
            <div></div>
            {/* <p><strong>Categoría:</strong> {vehicleCategories.find((vehicleCategory) => vehicleCategory.id === parseInt(vehicle.vehicle_type_id))?.category_name}</p> */}
            <p><strong>Tipo de vehículo:</strong> {vehicle.vehicle_type.type_name}</p>
            <p><strong>Patente:</strong> {vehicle.vehicle_plate}</p>
            <p><strong>Versión:</strong> {vehicle.version}</p>
            <p><strong>Año de fabricación:</strong> {vehicle.fabrication_year}</p>
            <p><strong>Fecha de adquisición:</strong> {ConvertDateToDayMonthYear(vehicle.acquisition_date)}</p>
            <p><strong>Estado:</strong> {vehicle.vehicle_status.status_name} </p>
            {vehicle.traction ? (<p><strong>Tracción:</strong> {vehicle.traction}</p>) : null}
            {vehicle.last_maintenance_date ? (<p><strong>Fecha de la última mantención:</strong> {ConvertDateToDayMonthYear(vehicle.last_maintenance_date)}</p>) : null}
            </div>
    
            
            <div className="md:w-1/2 flex flex-col items-center md:items-end p-4">
                <img
                    src={vehicle.image_url !== '' ? vehicleImage : NoPhotoImage}
                    alt={`${vehicle.brand} ${vehicle.model}`}
                    className="w-full md:w-auto md:max-w-xs lg:max-w-sm object-cover rounded-md"
                />
                    <div className="text-sm mt-2 w-full text-center">
                        {(vehicle.description !== '') ? (<div><strong> Descripción: </strong>{vehicle.description}</div>) : (null)}
                    </div>
                    {/* Buttons */}
                    <div className="mt-4 flex flex-col md:flex-row md:justify-end space-y-1 md:space-y-0 md:space-x-2">
                        <button
                            onClick={() => handleEdit(vehicle)}
                            className="text-white bg-customBlue hover:bg-blue-600 rounded-lg px-4 py-1"
                        >
                            Modificar vehículo
                        </button>
                    </div>
                </div>
            </div>
            <div className="text-center font-custom">
                <div>
                    <div className="text-xl font-bold">Historial del vehículo</div>
                </div>
                {
                    (vehicleRecords.length > 0) ? (
                        vehicleRecords.map((trip: any) => (
                        <div key={trip.id}> {trip.id} </div>
                        ))
                    ) : (
                        <div> El vehículo aún no ha sido solicitado</div>
                    )
                }
            </div>
            <VehicleTracker muestra={vehicle.vehicle_plate} message={"Revisa la trayectoria del vehículo"}/>
        </div>
    )
}
