export const Footer = () => {
    return (
        <footer className="bg-footerBackground p-4">
            <div className="max-w-6xl mx-auto flex justify-between items-center">
                <div className="flex items-center">
                    <img src="https://inet.dictuc.cl/intranet70/assets/images/logo_footer.png" alt="Logo" className="mr-2"/>
                </div>
            
                <div className="flex items-center space-x-4">
                    <a href="https://www.uc.cl/" className="text-white hover:text-gray-400">
                        <img src="https://inet.dictuc.cl/intranet70/assets/images/logo_uc_footer.png" alt="Logo UC" className="mr-2"/>
                    </a>
                    <a href="https://www.ing.uc.cl/" className="text-white hover:text-gray-400">
                        <img src="https://inet.dictuc.cl/intranet70/assets/images/logo_escuela.png" alt="Logo Ingeniería UC" className="mr-10"/>
                    </a>
                    <a href="https://www.linkedin.com/company/dictuc-sa/" className="text-white hover:text-gray-400">
                        <img src="https://inet.dictuc.cl/intranet70/assets/images/logo_linkedin.png" alt="Logo LinkedIn" className="mr-2"/>
                    </a>
                    <p className="text-white text-lg font-bold">v1.0 <span className="text-xs">(02-08-2024)</span></p>
                </div>
            </div>
        </footer>
    )
}