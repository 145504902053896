import React, { useEffect } from 'react';
import { Modal, Box } from '@mui/material';

type FilePreviewProps = {
  file: File | string;
  source: string;
  handleOpen: () => void; 
  open: boolean; 
  handleClose: () => void;
};

const FilePreview: React.FC<FilePreviewProps> = ({ file, source, handleOpen, open, handleClose }) => {
    const [previewUrl, setPreviewUrl] = React.useState<string | null>(null);
    
    useEffect(() => {
      console.log(file);
      const processFile = async () => {
        if (source === "local" && file instanceof File) {
          const reader = new FileReader();

          reader.onloadend = () => {
            if (reader.result && typeof reader.result === 'string') {
              setPreviewUrl(reader.result);
            } else {
              console.error('Failed to load the file as a base64 string');
              setPreviewUrl(null);
            }
          };

          reader.onerror = () => {
            console.error('Error while reading the file');
            setPreviewUrl(null);
          };

          if (file.type.match('image.*') || file.type === 'application/pdf' || file.type.match('image/*')) {
            reader.readAsDataURL(file);
          } else {
            console.error('Unsupported file type');
            setPreviewUrl(null);
          }
        } else if (source === "cloud" && typeof file === 'string') {
          setPreviewUrl(file);
        } 
      };
      processFile()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file, source]);

    const renderPreview = () => {
      if (!previewUrl) {
        return <p>Cargando...</p>;
      }

      if (previewUrl.match(/data:image\/(jpeg|jpg|png);base64,/)) {
        return <img src={previewUrl} alt="Preview" style={{ width: '100%' }} />;
      } else if (previewUrl.match(/data:application\/pdf;base64,/)) {
        return <embed src={previewUrl} width="100%" height="600px" />;
      } else {
        return <img src={previewUrl} alt="Preview" style={{ width: '100%' }} />;
      }
    };

    return (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            style={{ zIndex: 1300 }}
          >
          <Box
            component="section"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              maxHeight: '90vh',
              overflowY: 'auto',
              color: 'background.paper',
              boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)',
            }}
          >
              {renderPreview()}
            </Box>
          </Modal>
        </>
    );
};

export default FilePreview;
