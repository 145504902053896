import React, { useState, useEffect } from 'react';
import performApiRequest from '../../../../utils/performApiRequest';
import { useAuthStatus } from '../../../../hooks/useAuthStatus';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import { useDataForEndpoint } from '../../../../context/dataProvider';
import Loader from '../../../../elements/loader';
import { OrganizationalUnit } from '../../../../types/fields';
import 'react-datepicker/dist/react-datepicker.css';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { API_ENDPOINTS } from '../../../../utils/endpoints';
import { useToast } from '../../../../context/useToast';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

interface User {
  id: string;
  names: string;
  ap_pat: string;
  ap_mat: string;
  rut: string;
  dv_rut: string;
  startDate: Date;
  endDate: Date;
  position: number;
}

interface Step {
  id: string;
  users: User[];  // Directly place users array here
}

interface AdministratorPanelProps {
  selectedUnitId?: string;
  selectedUnitName?: string;
}

// export default function ApproversAdministratorView () {
//   return (
//       <Layout 
//           children={<ApproversAdministratorPanel/>} 
//           sectionTitle="Administra las cadenas de aprobación de los gastos"
//       />
//   )
// }

export default function ApproversAdministratorPanel({ selectedUnitId, selectedUnitName }: AdministratorPanelProps) {
  const isAuthenticated = useAuthStatus();
  const [steps, setSteps] = useState<Step[]>([]);
  const organizationalUnits = useDataForEndpoint<OrganizationalUnit[]>('organizational_units');
  const [availableUsers, setAvailableUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { showSuccessToast, showErrorToast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        const requestOptions = {
          method: 'GET',
        };
        const response = await performApiRequest(`/v1/users/getUsersInfoAll`, requestOptions);
        console.log('response users:', response.data);
        setAvailableUsers(response.data.usersData);
      } catch (error) {
        console.error('Error fetching all users', error);
      }
    };
    fetchUsersData();
  }, [isAuthenticated]);

  useEffect(() => {
    const allData = [
      organizationalUnits,
    ];
    
    if (isAuthenticated) {
        const isAnyDataUndefined = allData.some(data => data === undefined);
        setIsLoading(isAnyDataUndefined);
    } // Set loading to false if all data is loaded
  }, [organizationalUnits, isAuthenticated]);

  useEffect(() => {
    if (selectedUnitId) {
      fetchApproversByUnitData(selectedUnitId);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnitId]);
  
  const fetchApproversByUnitData = async (unitId: string) => {
    setIsLoading(true);
    const requestOptions = {
      method: 'GET'
    };
    try {
      const response = await performApiRequest(
        `${API_ENDPOINTS.getExpenseApproverFromOrganizationalUnit}/${unitId}`,
        requestOptions
      );
      if (response.status === 200) {
        const formattedSteps = response.data.approvers.map((step: Step) => ({
          id: step.id,
          users: step.users.map((user) => ({
            ...user,
            startDate: new Date(user.startDate),
            endDate: new Date(user.endDate)
          }))
        }));
        setSteps(formattedSteps);
      } else if (response.status === 204) {
        setSteps([]);
      }
    } catch (error) {
      console.error('Failed to fetch approvers:', error);
    }
    setIsLoading(false);
  };

  const handleOnDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;
  
    const sourceIndex = steps.findIndex(step => `step-${step.id}` === source.droppableId);
    const destIndex = steps.findIndex(step => `step-${step.id}` === destination.droppableId);
  
    if (sourceIndex === -1 || destIndex === -1) return;
  
    const sourceStep = steps[sourceIndex];
    const destStep = steps[destIndex];
  
    if (destStep.users.length >= 2) {
      alert("Puede haber un máximo de dos usuarios por etapa.");
      return;
    }
  
    const [removedUser] = sourceStep.users.splice(source.index, 1);
    destStep.users.splice(destination.index, 0, removedUser);
    const updatedSteps = steps.filter(step => step.users.length > 0);

    setSteps(updatedSteps);
  };

  const handleAddUser = (userId: string) => {
    const userToAdd = availableUsers.find(user => Number(user.id) === Number(userId));
    if (!userToAdd) {
        console.error("User not found");
        return;
    }

    const newStep = {
        id: `step-${steps.length + 1}`, // Use the length to ensure unique ID
        users: [{
            ...userToAdd,
            startDate: new Date(),
            endDate: new Date()
        }]
    };

    setSteps(prevSteps => [...prevSteps, newStep]);

    setAvailableUsers(prevUsers => prevUsers.filter(user => Number(user.id) !== Number(userId)));
  };

  const handleDeleteUser = (userId: string, stepId: string) => {
    setSteps(prevSteps => {
        const newSteps = prevSteps.map(step => {
            if (step.id === stepId) {
                const filteredUsers = step.users.filter(user => user.id !== userId);
                return { ...step, users: filteredUsers };
            }
            return step;
        }).filter(step => step.users.length > 0);  // Remove step if no users left

        // Recalculate step IDs to ensure they are consecutive
        return newSteps.map((step, index) => ({
            ...step,
            id: `step-${index + 1}`
        }));
    });
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>, dateType: 'startDate' | 'endDate', userId: string, stepId: string) => {
    const dateValue = event.target.value;  // Already in 'YYYY-MM-DD' format
  
    if (!dateValue) {
      console.error("Selected date is invalid.");
      return;
    }
    setSteps(prevSteps => prevSteps.map(step => {
      if (step.id === stepId) {
        const updatedUsers = step.users.map(user => {
          if (Number(user.id) === Number(userId)) {
            const tempUser = { ...user, [dateType]: new Date(dateValue) };  // Convert back to Date for internal use if necessary
            if (CheckIfDatesOverlap(tempUser, step.users.filter(u => Number(u.id) !== Number(userId)))) {
              alert('Las fechas de validez de los aprobadores no pueden topar.')
              return user;
            }
            return tempUser;
          }
          return user;
        });
        return { ...step, users: updatedUsers };
      }
      return step;
    }));
  };

  const CheckIfDatesOverlap = (currentUser: User, otherUsers: User[]) => {
    return otherUsers.some(otherUser =>
        (currentUser.startDate <= otherUser.endDate && currentUser.endDate >= otherUser.startDate) ||
        (currentUser.endDate >= otherUser.startDate && currentUser.startDate <= otherUser.endDate)
    );
  };

  const handleSubmit = async () => {
    if (!selectedUnitId) {
      alert("Debes seleccionar una unidad.");
      return;
    }
  
    // Ensure each step has at least one user and all users have valid dates
    for (const step of steps) {
      if (step.users.length === 0) {
        alert("Por cada nivel debe haber al menos un aprobador.");
        return;
      }
      for (const user of step.users) {
        if (!user.startDate || !user.endDate) {
          alert("Cada aprobador debe tener una fecha de inicio y de término para aprobar.");
          return;
        }
      }
    }
  
    const payload = {
      unitId: selectedUnitId,
      steps: steps.map(step => ({
        users: step.users.map(user => ({
          userId: user.id,
          startDate: user.startDate,
          endDate: user.endDate
        }))
      }))
    };
  
    try {
      const requestOptions = {
        method: 'POST',
        data: payload
      }
      const response = await performApiRequest(API_ENDPOINTS.addExpenseApproverToOrganizationalUnit, requestOptions);
      if (response.status === 201) {
        showSuccessToast('Los datos se actualizaron con éxito.')
      }
    } catch (error: any) {
        showErrorToast(`Error: ${error.message}`);
    }
  };

  const userOptions = availableUsers.map(user => ({
    value: user.id,
    label: `${user.names} ${user.ap_pat}. RUT: ${user.rut}-${user.dv_rut}`,
  }));

  if (isLoading) {
    return <Loader/>
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex flex-col justify-center items-center mb-4">
        <h2 className="font-bold mb-2 text-lg"> Unidad de rendición: {'\n'} {selectedUnitName} </h2>
      </div>
      <div className="flex flex-col justify-center items-center mb-4">
        <h3 className="font-semibold mb-2"> Selecciona usuarios para agregarlos al flujo. </h3>
        <Select
              options={userOptions}
              onChange={(selectedOption: any) => handleAddUser(selectedOption.value)}
              placeholder="Selecciona o busca un usuario..."
              isClearable
              isSearchable
              className="min-w-64"
        />
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        {steps.map((step, index) => (
          <Droppable key={step.id} droppableId={`step-${step.id}`}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <h4 className="mb-4 mt-4 font-semibold">Aprobadores de nivel {index + 1}</h4>
                {step.users.map((user, idx) => (
                  <Draggable key={user.id} draggableId={user.id.toString()} index={idx}>
                    {(provided) => (
                      <div
                        className="w-full grid grid-cols-1 md:grid-cols-4 gap-4 my-2 justify-evenly items-center flex flex-row bg-beige py-2 px-4 user"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        >
                        <div>
                          {user.names} {user.ap_pat} {user.ap_mat}. {'\n'} RUT: {user.rut}-{user.dv_rut}
                        </div>
                        <div>
                          <label className="justify-center"> Desde: </label> 
                          <input
                            type="date"
                            value={user.startDate.toISOString().substring(0, 10)}  // Convert Date object to YYYY-MM-DD format
                            onChange={(event) => handleDateChange(event, 'startDate', user.id, step.id)}
                            max={user.endDate ? user.endDate.toISOString().substring(0, 10) : ''}
                          />
                        </div>
                        <div>
                          <label className="justify-center"> Hasta: </label>
                          <input
                            type="date"
                            value={user.endDate.toISOString().substring(0, 10)}  // Convert Date object to YYYY-MM-DD format
                            onChange={(event) => handleDateChange(event, 'endDate', user.id, step.id)}
                            min={user.startDate.toISOString().substring(0, 10)}
                          />
                        </div>
                        <div> 
                          <button
                            className="ml-5"
                            onClick={() => handleDeleteUser(user.id, step.id)}>
                            <PersonRemoveIcon/>
                          </button>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </DragDropContext>
      <div className="w-full justify-center items-center font-semibold bg-beige text-center flex flex-col">
        <span>El último paso de aprobación lo realiza Contabilidad.</span>
        <span>No se debe agregar al flujo.</span>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <button
        onClick={() => navigate('/expenseReportSystemAdministration')}
        className={`submit-button py-2 px-5 rounded-md bg-red-600 hover:bg-red-800 text-white mt-5`}>
        Volver
      </button>
      <button
        onClick={handleSubmit}
        disabled={steps.length < 1}
        className={`submit-button py-2 px-5 rounded-md bg-blue-400 hover:bg-blue-600 text-white mt-5 ${steps.length < 1 ? 'bg-grey-800':'bg-blue-400'}`}>
        Guardar
      </button>
      </div>
    </div>
  );
}
