import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Currency } from '../types/fields';
import performApiRequest from '../utils/performApiRequest';
import { useDataForEndpoint } from '../context/dataProvider';
import { API_ENDPOINTS } from '../utils/endpoints';
import Loader from './loader';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ThemeProvider } from '@mui/material/styles';
import { esES } from '@mui/x-date-pickers/locales';
import theme from '../utils/calendarTheme';
import 'dayjs/locale/es';
import { useToast } from '../context/useToast';
import HighlightOff from '@mui/icons-material/HighlightOff';
// import showExactDate from '../utils/showExactDate';
// import { format } from 'date-fns';

interface UploadFormModalProps {
    onClose: () => void;
    actual_request_id?: number;
    file_data?: {
        id: number;
        file_url: string;
        request_id: number;
        document_number: string;
        expense_date: string;
        total_amount: number;
        currency_id: string;
        business_rut: string;
        commentary: string;
    };
}

const getValidationSchema = (fileData: any) => {
    const fileSchema = fileData ? 
      Yup.mixed().test('fileType', 'El archivo debe ser de tipo PDF o imagen.', (value) => {
        if (!value || typeof value !== 'object') return true;
        const file = value as File;
        const supportedFileTypes = ['application/pdf', 'image/jpeg', 'image/png'];
        return supportedFileTypes.includes(file.type);
      }) : 
      Yup.mixed().required('Debes adjuntar el archivo.').test('fileType', 'El archivo debe ser de tipo PDF o imagen.', (value) => {
        if (!value || typeof value !== 'object') return true;
        const file = value as File;
        const supportedFileTypes = ['application/pdf', 'image/jpeg', 'image/png'];
        return supportedFileTypes.includes(file.type);
      });
  
    return Yup.object().shape({
      document_number: Yup.string().required('Ingresa el número del documento.'),
      expense_date: Yup.string().required('Ingresa la fecha del documento'),
      total_amount: Yup.number().required('Debes ingresar el monto total del documento.'),
      currency_id: Yup.string().required('Debes seleccionar la divisa en la que está el monto.'),
      business_rut: Yup.string().required('Debes ingresar el RUT de la compañía.').min(2, 'Debe tener un mínimo de dos caracteres.'),
      file: fileSchema,
      commentary: Yup.string(),
    });
  };
  

const UploadTravelAllowanceRequestJustificationFile: React.FC<UploadFormModalProps> = ({ onClose, actual_request_id, file_data }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [filePreview, setFilePreview] = useState<string | null>(file_data ? file_data.file_url : null);
    const [isLoading, setIsLoading] = useState(true)
    const currencies = useDataForEndpoint<Currency[]>('currencies');
    const [checkMessagePresence, setCheckMessagePresence] = useState(false);
    const { showSuccessToast, showErrorToast } = useToast();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [selectedExpenseDate, setSelectedExpenseDate] = useState<Dayjs>(file_data ? dayjs(new Date(file_data.expense_date)) : dayjs());

    useEffect(() => {
        const allData = [
          currencies,
        ];
        const isAnyDataUndefined = allData.some(data => data === undefined);
        setIsLoading(isAnyDataUndefined);
    }, [currencies]);

    const handleDateChange = (date: Dayjs | null) => {
        const formattedDate = date ? date.format('YYYY-MM-DD') : '';
        formik.setFieldValue('expense_date', formattedDate);
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const selectedFile = files[0];
            setUploadedFile(selectedFile);
            formik.setFieldValue('file', selectedFile);
            const reader = new FileReader();
            reader.onload = () => {
                const result = reader.result;
                if (result && typeof result === 'string') {
                    setFilePreview(result);
                }
            };
            reader.readAsDataURL(selectedFile);

            try {
                const formData = new FormData();
                formData.append('file', selectedFile);
                const requestOptions = {
                    method: 'POST',
                    data: formData
                }
                const response = await performApiRequest(API_ENDPOINTS.obtainTotalValueFromOCREndpoint, requestOptions);
                if (response.data.value !== "Not found") {
                    formik.setFieldValue('total_amount', response.data.value);
                    setCheckMessagePresence(true);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    const handleCancel = () => {
        setUploadedFile(null);
        setFilePreview(null);
        onClose();
    };

    const formik = useFormik({
        initialValues: {
            request_id: file_data ? file_data.request_id : (actual_request_id ?? 0),
            document_number: file_data?.document_number || '',
            expense_date: file_data?.expense_date || '',
            total_amount: file_data?.total_amount || 0,
            currency_id: file_data?.currency_id || '0',
            business_rut: file_data?.business_rut || '',
            file: '',
            commentary: file_data?.commentary || ''
        },
        validationSchema: getValidationSchema(file_data),
        onSubmit: async (values: 
            {
                request_id: number,
                document_number: string,
                expense_date: string,
                total_amount: number,
                currency_id: string,
                business_rut: string,
                file: string | Blob,
                commentary: string
            }) => {
            const formData = new FormData();
            if (values.file && values.file instanceof File) {
                formData.append('file', values.file);
            }
            Object.entries(values).forEach(([key, value]) => {
                if (key !== 'file') {
                    formData.append(key, value as string);
                }
            });
            const requestOptions = {
                method: 'PATCH',
                data: formData
            };
            if (file_data) {
                try {
                    const response = await performApiRequest(`${API_ENDPOINTS.editJustificationFileFromTravelAllowanceRequest}/${file_data.id}`, requestOptions);
                    if (response.status === 204) {
                        showSuccessToast('Archivo editado exitosamente.');
                        onClose();
                    }
                } catch (error: any) {
                    showErrorToast(`Error: ${error.message}`);
                }

            } else {
                try {
                    const response = await performApiRequest(API_ENDPOINTS.addJustificationFileToTravelAllowanceRequest, requestOptions);
                    if (response.status === 201) {
                        showSuccessToast('Archivo subido exitosamente.');
                        onClose();
                    }
                } catch (error: any) {
                    showErrorToast(`Error: ${error.message}`);
                }
            }
    }});

    if (isLoading) {
        return (<Loader/>)
    }

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
            <div className="relative top-20 mx-auto p-5 border w-10/12 shadow-lg rounded-md bg-white px-10 py-10">
                <div className="cursor-pointer inline-block close" onClick={handleCancel}>
                    <div className="px-2 py-1">
                        <span><HighlightOff fontSize="large"/></span>
                    </div>
                </div>
                <div className="mt-3 text-center">
                    <div className="flex flex-col md:flex-row p-4 bg-white shadow rounded-lg">
                        <div className="w-full md:w-1/2 p-4">
                            <h2 className="text-gray-700 text-lg font-bold mb-4">Subir archivo</h2>
                            <input
                                type="file"
                                onChange={handleFileChange}
                                accept="image/*, application/pdf"
                            />
                            {filePreview && (
                                <img src={filePreview} alt="File Preview" className="mt-4 max-w-full h-auto" />
                            )}
                        </div>
                    <div className="w-full md:w-1/2 p-4">
                        <h2 className="text-gray-700 text-lg font-bold mb-4">Datos del documento</h2>
                        <form className="bg-sectionBarBackgroundColor shadow-md rounded px-8 pt-6 pb-8 mb-4" encType="multipart/form-data" onSubmit={formik.handleSubmit}>
                            <div className="grid grid-cols-1 gap-6">
                                
                                <div>
                                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="brand">
                                        Número de documento <span className="italic">(requerido)</span>
                                    </label>
                                    <input
                                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="document_number"
                                        name="document_number"
                                        value={formik.values.document_number}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />

                                    {formik.touched.document_number && formik.errors.document_number ? (
                                        <div className="text-red-500 text-sm">{formik.errors.document_number}</div>
                                    ) : null}
                                </div>

                                <div>
                                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="business_rut">
                                        RUT de la empresa o equivalente <span className="italic">(requerido)</span>
                                    </label>
                                    <input
                                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="business_rut"
                                        name="business_rut"
                                        value={formik.values.business_rut}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />

                                    {formik.touched.business_rut && formik.errors.business_rut ? (
                                        <div className="text-red-500 text-sm">{formik.errors.business_rut}</div>
                                    ) : null}
                                </div>

                                <div>
                                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="total_amount">
                                        Monto total <span className="italic">(requerido)</span> {checkMessagePresence && '\nRevisa el monto.'}
                                    </label>
                                    <input
                                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="total_amount"
                                        name="total_amount"
                                        value={formik.values.total_amount}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />

                                    {formik.touched.total_amount && formik.errors.total_amount ? (
                                        <div className="text-red-500 text-sm">{formik.errors.total_amount}</div>
                                    ) : null}
                                </div>

                                <div>
                                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="vehicle_type_id">
                                        Divisa <span className="italic">(requerido)</span>
                                    </label>
                                    <select
                                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="currency_id"
                                        name="currency_id"
                                        value={formik.values.currency_id}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    >
                                        <option value="">Seleccione una divisa</option>
                                        {currencies.map((type: any) => (
                                            <option key={type.id} value={type.id}>
                                                {type.currency_code}
                                            </option>
                                        ))}
                                    </select>
                                    {formik.touched.currency_id && formik.errors.currency_id ? (
                                        <div className="text-red-500 text-sm">{formik.errors.currency_id}</div>
                                    ) : null}
                                </div>

                                <div>
                                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="fullname">
                                        Fecha
                                    </label>
                                    <div className="w-full">
                                        <ThemeProvider theme={theme}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es" localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                                            <DatePicker
                                                label="Fecha"
                                                disableFuture
                                                value={selectedExpenseDate ? dayjs(selectedExpenseDate) : null}
                                                onChange={handleDateChange}
                                        />
                                        </LocalizationProvider>
                                        </ThemeProvider>
                                    </div>
                                    {formik.touched.expense_date && formik.errors.expense_date ? (
                                        <div className="text-red-500 text-sm">{formik.errors.expense_date}</div>
                                    ) : null}
                                </div>

                                <div>
                                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="total_amount">
                                        Comentario
                                    </label>
                                    <textarea
                                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="commentary"
                                        name="commentary"
                                        value={formik.values.commentary}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        rows={2}
                                        style={{ resize: "vertical" }}
                                    />

                                    {formik.touched.commentary && formik.errors.commentary ? (
                                        <div className="text-red-500 text-sm">{formik.errors.commentary}</div>
                                    ) : null}
                                </div>

                                <button
                                    className={`font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-blue-500 hover:bg-blue-700 text-white`}
                                    type="submit"
                                >
                                    { file_data ? 'Actualizar documento' : 'Subir documento' }
                                </button>

                            </div>
                        </form>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
};

export default UploadTravelAllowanceRequestJustificationFile;