import React, { FC } from 'react';
import { useNavigate } from 'react-router';

type DropdownOption = {
  label: string;
  value: string;
};

type DropdownProps = {
  options: DropdownOption[];
  title: string;
};

const DropdownButton: FC<DropdownProps> = ({ options, title }) => {
  const navigate = useNavigate();
  const handleOptionClick = (value: string) => {
    navigate(value);
  };

  return (
    <div className="flex-grow relative group text-customBlue text-md flex items-center justify-center w-full sm:w-auto sm:h-full">
      <button className="w-full h-full bg-topBarGrey hover:bg-selectedTopBarMenuButton font-medium py-2 px-4 sm:px-6 md:px-8 inline-flex items-center justify-center relative z-10">
        <span>{title}</span>
        <span className="ml-2 transform group-hover:rotate-180">&#x25BC;</span>
      </button>
      <div className="absolute left-0 right-0 top-full bg-white border rounded-md hidden group-hover:block z-20">
        {options.map((option) => (
          <button
            key={option.value}
            onClick={() => handleOptionClick(option.value)}
            className="text-sm text-gray-700 hover:bg-blue-500 hover:text-white block px-4 py-2 w-full text-left"
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default DropdownButton;