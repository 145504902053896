import { useState, useEffect } from 'react';
import VerticalLogo from "./../../assets/images/VerticalLogo.png"
import { useAuthStatus } from '../../hooks/useAuthStatus';
import { useAccessPermissions } from "../../hooks/useAccessPermission";
import { TextEditor } from "./textEditor";
import { useToast } from '../../context/useToast';
import performApiRequest from '../../utils/performApiRequest';
import { API_ENDPOINTS } from '../../utils/endpoints';
import { Post } from '../../types/fields';
import DOMPurify from 'dompurify';
import Loader from '../../elements/loader';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom'
import { AccessPermissions } from '../../types/fields';

export default function LandingPage () {
  	const isAuthenticated = useAuthStatus();
  	const { accessPermissions } = useAccessPermissions() as { accessPermissions: AccessPermissions };
  	const [textEditorIsOpen, setTextEditorIsOpen] = useState(false);
	const [posts, setPosts] = useState<Post[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const navigate = useNavigate();
  	const [editorContent, setEditorContent] = useState(
    	"<p style='text-align: center'>Escribe una noticia...</p>"
  	);
  	const { showSuccessToast, showErrorToast } = useToast();

  	useEffect(() => {
    	if (isAuthenticated) {
			const fetchPosts = async () => {
				try {
					const endpoint = API_ENDPOINTS.getPosts;
					const requestOptions = {
						method: 'GET',
					}
					const response = await performApiRequest(endpoint, requestOptions);
					setPosts(response.data.posts);
					setIsLoading(false);
				} catch (error) {
					console.error('There was an error fetching the Travel Allowance requests:', error);
				}
			};
			fetchPosts();
		}
	}, [isAuthenticated]);

	const handleSubmit = async () => {
		const contentHTML = editorContent;
		if (0 < contentHTML.length && contentHTML.length < 500) {
			const requestOptions = {
				method: 'POST',
				data: { content: contentHTML }
			}
			const response = await performApiRequest(API_ENDPOINTS.createPost, requestOptions);
			
			try {
				if (response.status === 201) {
					showSuccessToast('Publicación creada correctamente.');
					window.location.reload();
				}
			} catch (error: any) {
				showErrorToast(`Error: ${error.message}`);
			}

		} else {
			window.alert('El contenido de la noticia debe tener entre 1 y 500 caracteres.');
		}
	};

	const handleDeletePost = async (postId: number) => {
		// eslint-disable-next-line no-restricted-globals
		const confirmDelete = window.confirm("¿Deseas eliminar la noticia?");
	
		// Check if the user confirmed the deletion
		if (confirmDelete) {
			const requestOptions = {
				method: 'DELETE'
			};
			try {
				const response = await performApiRequest(
					`${API_ENDPOINTS.deletePost}/${postId}`,
					requestOptions
				);
				if (response.status === 204) {
					showSuccessToast('Publicación eliminada correctamente.');
					window.location.reload();
				} else {
					showErrorToast('No se pudo eliminar la publicación.');
				}
			} catch (error) {
				showErrorToast('Ocurrió un error al eliminar la publicación.');
			}
		}
	};

	return (
		<div className="container mx-auto mt-2 sm:mt-4 md:mt-6 lg:mt-8 xl:mt-10">
		{isAuthenticated ? (
			<main>
			<div className="flex-row">
				<div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex-row">
				<div className="flex">
					<div className="w-1/4">
					<img src={VerticalLogo} alt="Logo vertical de Dictuc S.A." className="object-scale-down sm:h-24 md:h-60 xl:h-75 sm:w-24 md:w-60 xl:w-75" />
					</div>
					<div className="w-3/4 px-5">
						<h2 className="text-gray-700 text-md font-bold mb-4 sm:mb-6 md:mb-8 lg:mb-10 xl:mb-12">Te damos la bienvenida al sistema de control de gastos operacionales.</h2>
						{ (accessPermissions.travelManagementSystem.observerPermissions) && (
							<button className="bg-blue-400 hover:bg-blue-600 rounded-md px-2 py-2 mb-5"
								onClick={() => setTextEditorIsOpen(!textEditorIsOpen)}>
								{(textEditorIsOpen) ? (
									<div className="font-custom text-white">
										Cerrar editor de texto
									</div>
								) : (
									<div className="font-custom text-white">
										Quiero escribir una noticia
									</div>
								)}
							</button>
						)}
						{ (textEditorIsOpen) && (
							<div className=" w-full flex flex-col justify-left gap-2">
								<TextEditor content={editorContent} setContent={setEditorContent} handleSubmit={handleSubmit}/>
							</div>
						) }
						<p className="text-gray-700 text-md font-bold mb-5 mt-5">Noticias recientes</p>
						{ (isLoading) ? (
							<Loader/>
						) : 
							( posts.length > 0 ) ? (
								posts.map((post: Post, index: number) => {
									return (
										<div key={post.id}>
											<div className="bg-gray-100 rounded-md px-2 py-2 mb-5">
												<div className="flex flex-row justify-between items-center w-full mb-5">
													<div>
														<p className="text-gray-700 text-md">
															{post.action_datetime} - { }
															<span className="text-xs">
																Publicado por {post.userData.names} {post.userData.ap_pat} {post.userData.ap_mat}
															</span>
														</p>
													</div>
													{(accessPermissions.travelManagementSystem.observerPermissions) && (
														<div>
															<button onClick={() => handleDeletePost(post.id)}>
																<DeleteIcon/>
															</button>
														</div>
													)}
												</div>
												<div className="text-gray-700 text-md">{<HtmlContent htmlString={post.content}/>}</div>
											</div>
											{index < posts.length - 1 && <hr className="my-4 border-t-1 border-gray-300" />}
										</div>
									)
								})
							) : (
								<div className="text-sm md:text-md"> Aún no hay noticias disponibles. </div>
							)}
						</div>
					</div>
				</div>
			</div>
			</main>
			) : (
			<main>
				<div className="flex-row">
				<div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex-row">
					<div className="flex">
					<div className="w-3/4"> {/* 75% width for text */}
						<h2 className="text-gray-700 text-lg font-bold mb-4 sm:mb-6 md:mb-8 lg:mb-10 xl:mb-12">Te damos la bienvenida al sistema de control de gastos operacionales.</h2>
						<p className="text-gray-700 text-md font-bold">En este sistema puedes realizar las siguientes acciones:</p>
						<ul className="list-disc pl-5">
						<li>Realizar solicitudes de viáticos y de rendición de gastos.</li>
						<li>Ver el estado de tus solicitudes.</li>
						<li>Visualizar los vehículos disponibles para tus viajes.</li>
						</ul>
					</div>
					<div className="w-1/4">
						<img src={VerticalLogo} alt="Logo vertical de Dictuc S.A." className="object-scale-down sm:h-24 md:h-60 xl:h-75 sm:w-24 md:w-60 xl:w-75" />
					</div>
					</div>
				</div>
				</div>
			</main>    
			)
		}
		<footer>
			<p className="text-center text-gray-500 text-xs">
			Si necesitas asistencia para utilizarlo, puedes revisar el manual de uso.
			</p>
			{accessPermissions.adminPermissions && (
				<p onClick={() => navigate('/ITPermissions-restricted')} className="mt-5 text-center text-gray-500 text-xs font-semibold">
					Edita los permisos de los usuarios en la aplicación
				</p>
			)}
		</footer>
		</div>
	)
}


interface HtmlContentProps {
	htmlString: string;
}
  
const HtmlContent: React.FC<HtmlContentProps> = ({ htmlString }) => {
	const safeHtmlString = DOMPurify.sanitize(htmlString);
	return <div dangerouslySetInnerHTML={{ __html: safeHtmlString }} />;
};