import DropdownButton from '../../elements/dropdownButton'
import { useAuthStatus } from '../../hooks/useAuthStatus';
import LoginButton from '../../elements/loginButton';
import { useNavigate } from "react-router-dom";
import { useAccessPermissions } from '../../hooks/useAccessPermission';

type ExpenseReportSystemPermission = 'adminPermissions' | 'submitterPermissions' | 'approverPermissions' | 'observerPermissions' | 'monitorPermissions' | 'accountingPermissions' | 'treasuryPermissions';
type TravelManagementSystemPermission = 'adminPermissions' | 'observerPermissions' | 'accountingPermissions' | 'treasuryPermissions' | 'monitorPermissions' | 'vehicleAdminPermissions' | 'informerPermissions' | 'regularPermissions';

const MenuButton = ({ title, value }: { title: string, value: string }) => {
  const navigate = useNavigate();
  const handleOptionClick = (value: string) => {
    navigate(value);
  };

  return (
    <button
      className="text-customBlue text-md flex-1 sm:w-full h-full justify-center bg-topBarGrey hover:bg-selectedTopBarMenuButton font-medium py-2 px-4 sm:px-6 md:px-8 inline-flex items-center flex-grow"
      onClick={() => handleOptionClick(value)}
    >
      {title}
    </button>
  );
};

const dropdownOptions = {
    vehicles: [
      { label: 'Lista de vehículos', value: '/allVehicles', permissionRequired: ['regularPermissions'] },
      { label: 'Lista de conductores', value: '/vehicleUsagePerUser', permissionRequired: ['vehicleAdminPermissions', 'monitorPermissions']},
      { label: 'Calendario del uso de vehículos', value: '/calendarOfVehicleUse', permissionRequired: ['vehicleAdminPermissions', 'monitorPermissions'] }
    ],
    
    monitoring: [
      { label: 'Reporte semanal de viáticos', value: '/alertReports', permissionRequired: ['monitorPermissions'] },
      { label: 'Estadísticas sobre viáticos', value: '/travelAllowanceStatistics', permissionRequired: ['monitorPermissions'] },
      { label: 'Actividad en la aplicación', value: '/allActivityRegistry', permissionRequired: ['monitorPermissions'] }
    ],
};

const travelAllowanceDropdownOptions = [
  { label: 'Enviar solicitud de viático', value: '/requestTravelAllowance' },
  { label: 'Mis solicitudes', value: '/allMyTravelAllowanceRequests' },
  { label: 'Revisar solicitudes', value: '/allTravelAllowanceRequests' }
];

const expenseReportDropdownOptions = [
  { label: 'Enviar rendición de gastos', value: '/requestExpenseReport', permissionRequired: ['submitterPermissions', 'adminPermissions'] },
  { label: 'Mis solicitudes', value: '/allMyExpenseReportRequests', permissionRequired: ['submitterPermissions', 'adminPermissions'] },
  { label: 'Revisar solicitudes', value: '/allExpenseReportRequests', permissionRequired: ['approverPermissions', 'adminPermissions','accountingPermissions', 'treasuryPermissions','observerPermissions'] },
  { label: 'Reporte semanal del sistema', value: '/alertReports', permissionRequired: ['adminPermissions', 'monitorPermissions','accountingPermissions', 'treasuryPermissions'] },
  { label: 'Administración del sistema', value: '/expenseReportSystemAdministration', permissionRequired: ['adminPermissions'] }
];

export const Header = () => {
  const isAuthenticated = useAuthStatus();
  const { accessPermissions } = useAccessPermissions();

  console.log('accessPermissions: ',accessPermissions);

  const hasExpensePermissions = (permissionsRequired: ExpenseReportSystemPermission[] | null): boolean => {
    if (permissionsRequired === null) return true;
    return permissionsRequired.some(permission => accessPermissions.expenseReportSystem[permission] === true);
  };

  console.log('hasExpensePermissions: ',hasExpensePermissions);

  const hasTravelPermissions = (permissionsRequired: TravelManagementSystemPermission[] | null): boolean => {
    if (permissionsRequired === null) return true;
    return permissionsRequired.some(permission => accessPermissions.travelManagementSystem[permission] === true);
  };
  
  const buttonCounter = () => {
    let buttonNumber = 4;
    if (accessPermissions.travelManagementSystem.monitorPermissions || accessPermissions.travelManagementSystem.adminPermissions) {
      buttonNumber++;
    }
    return buttonNumber;
  }

  const columns = buttonCounter();

  const customGridStyles = window.matchMedia("(min-width: 640px)").matches
  ? { gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))` }
  : { gridTemplateColumns: '1fr' };

  return (
    <header>
        <div className="flex flex-row items-center py-4 bg-customBlue px-4">
            <div className="text-center flex flex-col justify-center flex-grow">
                <h1 className="text-xl font-bold text-white">Sistema de control de gastos operacionales</h1>
                <h2 className="text-xl font-bold text-white">Dictuc S.A.</h2>
            </div>
            <div className="flex flex-col justify-end h-6/12">
              <LoginButton/>
            </div>
        </div>
        <nav className="flex flex-col md:flex-row items-center justify-center bg-topBarGrey sm:px-0 md:px-20 vw">
          <div className="flex items-center justify-center h-20 sm:w-screen">
              { isAuthenticated ? (
                <div className="grid sm:grid-cols-1 sm:w-full items-center justify-between h-full w-full"
                style={customGridStyles}
                >
                  <MenuButton title="Inicio" value="/"/>
                  <DropdownButton title="Solicitudes de viático" options={travelAllowanceDropdownOptions}/>
                  {expenseReportDropdownOptions.some(option => hasExpensePermissions(filterExpenseReportPermissions(option.permissionRequired))) && (
                    <DropdownButton title="Rendiciones de gastos" options={expenseReportDropdownOptions.filter(option => hasExpensePermissions(filterExpenseReportPermissions(option.permissionRequired)))} />
                  )}
                  {dropdownOptions.monitoring.some(option => hasTravelPermissions(filterTravelManagementPermissions(option.permissionRequired))) && (
                    <DropdownButton title="Seguimiento" options={dropdownOptions.monitoring.filter(option => hasTravelPermissions(filterTravelManagementPermissions(option.permissionRequired)))} />
                  )}
                  {dropdownOptions.vehicles.some(option => hasTravelPermissions(filterTravelManagementPermissions(option.permissionRequired))) && (
                    <DropdownButton title="Vehículos" options={dropdownOptions.vehicles.filter(option => hasTravelPermissions(filterTravelManagementPermissions(option.permissionRequired)))} />
                  )}
                </div>
              ) : (
                <h1 className="text-lg font-bold text-center text-footerBackground"> Inicia sesión para utilizar el sistema.</h1>
              )}
            </div>
        </nav>
    </header>
  )
}

function filterExpenseReportPermissions(permissions: string[]): ExpenseReportSystemPermission[] {
  const validPermissions: ExpenseReportSystemPermission[] = ['adminPermissions', 'submitterPermissions', 'approverPermissions', 'observerPermissions', 'monitorPermissions', 'accountingPermissions', 'treasuryPermissions'];
  return permissions.filter(permission => validPermissions.includes(permission as ExpenseReportSystemPermission)) as ExpenseReportSystemPermission[];
}

function filterTravelManagementPermissions(permissions: string[]): TravelManagementSystemPermission[] {
  // if (!permissions) return [];
  const validPermissions: TravelManagementSystemPermission[] = ['adminPermissions', 'observerPermissions', 'accountingPermissions', 'treasuryPermissions', 'monitorPermissions', 'vehicleAdminPermissions', 'informerPermissions', 'regularPermissions'];
  return permissions.filter(permission => validPermissions.includes(permission as TravelManagementSystemPermission)) as TravelManagementSystemPermission[];
}