export const API_ENDPOINTS = {
    addJustificationFileToTravelAllowanceRequest: '/v1/travelAllowanceRequestController/addJustificationFileToTravelAllowanceRequest',
    editJustificationFileFromTravelAllowanceRequest: '/v1/travelAllowanceRequestController/editJustificationFileFromTravelAllowanceRequest',
    editCurrencyRateFromTravelAllowanceRequest: '/v1/travelAllowanceRequestController/editCurrencyRateFromRequest',
    deleteFileFromTravelAllowanceRequest: '/v1/travelAllowanceRequestController/deleteJustificationFileFromTravelAllowanceRequest',
    addFileToExpenseReportRequest: '/v1/expenseReportRequestController/addFileToExpenseReportRequest',
    editParticularExpenseInformation: '/v1/particularExpenseController/editExpenseInformation',
    deleteFileFromExpenseReportRequest: '/v1/expenseReportRequestController/deleteFileFromExpenseReportRequest',
    downloadExcelReportFromTravelAllowanceRequest: '/v1/travelAllowanceStatistics/obtainExcelSheet',
    downloadExcelReportFromExpenseReportRequest: '/v1/travelAllowanceStatistics/obtainExpenseReportExcelSheet',
    downloadExcelDataTransfer: '/v1/travelAllowanceStatistics/obtainDataTransfer',
    downloadExcelExpenseDataTransfer: '/v1/travelAllowanceStatistics/obtainExpenseDataTransfer',
    downloadParticularExpenseReportExcelSheet: '/v1/travelAllowanceStatistics/obtainParticularExpenseReportExcelSheet',
    downloadJustificationFileFromTravelAllowanceRequest: '/v1/travelAllowanceRequestController/downloadFile',
    addExpenseApproverToOrganizationalUnit: '/v1/expenseApproverByUnitController/addExpenseApproverToOrganizationalUnit',
    getExpenseApproverFromOrganizationalUnit: '/v1/expenseApproverByUnitController/getExpenseApproverOfOrganizationalUnit',
    addExpenseSubmitterToOrganizationalUnit: '/v1/expenseSubmitterByUnitController/addExpenseSubmitterToOrganizationalUnit',
    getExpenseSubmitterFromOrganizationalUnit: '/v1/expenseSubmitterByUnitController/getExpenseSubmitterOfOrganizationalUnit',
    createBillAccount: '/v1/expenseReportRequestController/createBillAccount',
    obtainTravelAllowancesMainStatistics: '/v1/travelAllowanceStatistics/obtainMainStatistics',
    obtainTotalValueFromOCREndpoint: '/v1/fileReaderOCR/extractValueFromText',
    obtainVehicleTrajectory: '/v1/vehicularResourcesMonitor/getVehicleLocation',
    createPost: '/v1/postController/createPost',
    getPosts: '/v1/postController/getPosts',
    deletePost: '/v1/postController/deletePost',
    getCategoriesByUnit: '/v1/categoryController/retrieveExpenseCategoriesForSpecificUnit',
    getUnitsUserIsSubmitterOf: '/v1/expenseSubmitterByUnitController/getUnitsUserIsSubmitterOf',
    createExpenseReportRequest: '/v1/expenseReportRequestController/createExpenseReportRequest',
    updateExpenseReportRequest: '/v1/expenseReportRequestController/updateExpenseReportRequest',
    rejectExpenseReportRequest: '/v1/expenseReportRequestController/rejectExpenseReportRequest',
    getUsersWithRole: '/v1/systemAssignedRoleController/getUsersWithRole',
    addUsersToRole: '/v1/systemAssignedRoleController/addUsersToRole',
    validateExpenseReportRequestAsAccounting: '/v1/expenseReportRequestController/validateAsAccounting',
    markExpenseReportRequestAsApproved: '/v1/expenseReportRequestController/markRequestAsApproved',
    reviewExpenseReportRequest: '/v1/expenseReportRequestController/reviewRequest',
    sendExpenseReportRequestBackToSolicitant: '/v1/expenseReportRequestController/sendBackToSolicitant',
    obtainExchangeRate: '/v1/exchangeRateRetriever/obtainExchangeRate',
    createExpenseCategory: '/v1/categoryController/createExpenseCategory',
    editBillAccount: '/v1/expenseReportRequestController/editBillAccount',
    activateBillAccount: '/v1/expenseReportRequestController/activateBillAccount',
    deactivateBillAccount: '/v1/expenseReportRequestController/deactivateBillAccount',
    getExpenseCategories: '/v1/categoryController/getExpenseCategories',
    editExpenseCategory: '/v1/categoryController/editExpenseCategory',
    activateExpenseCategory: '/v1/categoryController/activateExpenseCategory',
    deactivateExpenseCategory: '/v1/categoryController/deactivateExpenseCategory',
}
