import { useAuthStatus } from '../../hooks/useAuthStatus';
import BlockScreen from '../blockScreen/blockScreen';
import { ShowAllExpenseReportRequests } from './showAllExpenseReportRequests';

export default function ShowMyExpenseReportRequests () {
    const isAuthenticated = useAuthStatus();

    if (!isAuthenticated) {
        return <BlockScreen/>
    }

    return (
      <div>
          <h2 className="py-3 text-gray-700 text-lg font-semibold">Rendiciones de gastos </h2> 
          <ShowAllExpenseReportRequests {...{endpointURL: '/v1/expenseReportRequestController/allMyExpenseReportRequests'}}/>
        </div>
    )
}
