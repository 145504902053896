import Layout from "../../components/layOutWrapper/layOutWrapper";
import { useState, useEffect } from 'react';
import performApiRequest from "../../utils/performApiRequest";
import { format } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'react-calendar/dist/Calendar.css';
import { useNavigate } from "react-router-dom";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { esES } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/es';
import dayjs, { Dayjs } from 'dayjs';

export default function VehiclesCalendarView () {
    return (
        <Layout sectionTitle="Calendario del uso de vehículos">
            <VehicleAvailabilityCalendar />
        </Layout>
    )
}

type LegendItem = {
  color: string;
  description: string;
};

type LegendProps = {
  data: LegendItem[];
};

const legendData = [
  { color: 'bg-red-500', description: 'Reservado' },
  { color: 'bg-yellow-500', description: 'Solicitado para un viaje' },
  { color: 'bg-green-500', description: 'Disponible' }
];

const Legend: React.FC<LegendProps> = ({ data }) => {
  return (
    <div className="flex flex-col border border-gray-300 p-4 rounded-lg mb-10 mt-5">
      <h3 className="text-xl font-semibold text-center mb-5">Simbología</h3>
      {data.map((item, index) => (
        <div key={index} className="flex items-center gap-2 my-1">
          <span className={`${item.color} w-4 h-4 inline-block rounded-full`}></span>
          <span className="text-sm">{item.description}</span>
        </div>
      ))}
    </div>
  );
};

type VehicleAvailability = {
  vehicle_id: number; 
  vehicle_plate: string;
  availability: { [date: string]: AvailabilityDetail };
};

type AvailabilityDetail = {
  hasRequest: boolean;
  id?: number;
  status?: string;
}

const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  esES,
);

const VehicleAvailabilityCalendar: React.FC = () => {
    const [vehicleAvailabilities, setVehicleAvailabilities] = useState<VehicleAvailability[]>([]);
    const [selectedStartDate, setSelectedStartDate] = useState<Date>(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());
    const navigate = useNavigate();
  
    useEffect(() => {
      const fetchVehicleAvailability = async () => {
        try {
          const startDate = format(selectedStartDate, 'yyyy-MM-dd');
          const endDate = format(selectedEndDate, 'yyyy-MM-dd');
          const requestOptions = {
            method: 'POST',
            data: { startDate, endDate }
          };
          const response = await performApiRequest(`/v1/vehicularResourcesMonitor/getVehiclesAvailability`, requestOptions);
          setVehicleAvailabilities(response.data);
        } catch (error) {
          console.error('Error fetching vehicle availability', error);
        }
      };
      if (selectedStartDate <= selectedEndDate) {
        fetchVehicleAvailability();
      }
    }, [selectedStartDate, selectedEndDate]);
  
    
    const handleCellClick = (requestId: number) => {
      navigate(`/particularTravelAllowanceRequest/${requestId}`)
    };

    const handleStartDateChange = (date: Dayjs | null) => {
      if (date) {
        const newDate = date.toDate();
        setSelectedStartDate(newDate);
      }
    };
    
    const handleEndDateChange = (date: Dayjs | null) => {
      if (date) {
        const newDate = date.toDate();
        setSelectedEndDate(newDate);
      }
    };
    
    const getCellColor = (dateDetails: AvailabilityDetail) => {
      if (dateDetails.hasRequest && ['Pendiente de validación por Contabilidad', 'Validada por Contabilidad', 'Aprobada - Pagada por Tesorería'].includes(dateDetails.status ?? '')) return 'bg-red-500';
      if (dateDetails.hasRequest && ['Enviada', 'Recibida', 'En proceso de revisión'].includes(dateDetails.status ?? '')) return 'bg-yellow-500';
      if (!dateDetails.hasRequest) return 'bg-green-500';
      return 'white';
    };
  
    return (
      <div>
        <div className="flex flex-col md:flex-row justify-between">
          <div className="flex flex-col">
            <h1 className="text-xl font-semibold mb-5">Selecciona un rango de fechas para visualizar la disponibilidad de vehículos</h1>
            <div className="flex flex-col md:flex-row justify-between">
              <div>
                  <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es" localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                      <DatePicker
                        label="Desde"
                        value={selectedStartDate ? dayjs(selectedStartDate) : null}
                        onChange={handleStartDateChange}
                        className="w-full"
                      />
                    </LocalizationProvider>
                  </ThemeProvider>
              </div>
              <div>
                  <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es" localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                      <DatePicker
                        label="Hasta"
                        value={selectedEndDate ? dayjs(selectedEndDate) : null}
                        onChange={handleEndDateChange}
                        className="w-full"
                      />
                    </LocalizationProvider>
                  </ThemeProvider>
              </div>
            </div>
          </div>
          <Legend data={legendData} />
        </div>
        <h6 className="text-sm font-bold mb-5">Puedes hacer clic en una celda de color rojo o amarillo para ver la solicitud asociada.</h6>

        <div className="overflow-x-auto">
          <table className="min-w-max">
            <thead>
              <tr className="semi-bold">
                <th className="sticky left-0 bg-white z-[1]">Patente</th>
                {Array.from({ length: calculateInclusiveDaysBetweenDates(selectedStartDate, selectedEndDate) }).map((_, index) => {
                  const date = new Date(selectedStartDate);
                  date.setDate(date.getDate() + index);
                  return (
                      <th key={index}>
                        <div className="py-5 mb-2 transform -rotate-90 origin-center whitespace-nowrap">{format(date, 'dd-MM-yy')}</div>
                      </th>
                    )
                })}
              </tr>
            </thead>
            <tbody>
              {vehicleAvailabilities.map((va) => (
                <tr key={va.vehicle_id}>
                  <td className="text-center w-32 md:w-40 lg:w-48 sticky left-0 bg-white z-[1]">{va.vehicle_plate}</td>
                  {Array.from({ length: calculateInclusiveDaysBetweenDates(selectedStartDate, selectedEndDate) }).map((_, index) => {
                    const date = new Date(selectedStartDate);
                    date.setDate(date.getDate() + index);
                    const dateString = format(date, 'dd/MM/yy');
                    const dateArray = va.availability[dateString];
                    const color = dateArray ? getCellColor(dateArray) : 'white';
                    return (
                      <td
                        key={index}
                        className={`relative py-2 ${color} border border-black`}
                        onClick={() => dateArray && dateArray.hasRequest ? handleCellClick(dateArray?.id ?? 0) : null}
                      >
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  function calculateInclusiveDaysBetweenDates(startDate: Date, endDate: Date) {
    const timeDifference: number = endDate.getTime() - startDate.getTime();
    const daysDifference: number = timeDifference / (1000 * 3600 * 24);
    return Math.round(daysDifference) + 1;
}