import { msalInstance } from "./auth/authContext";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { FetchOptions, CustomHeaders } from "../types/auth";
import { mockAccounts } from "./mockAccounts";
import axios from 'axios';

class ApiError extends Error {
    status: number;
    data: any;

    constructor(status: number, message: string, data?: any) {
        super(message);
        this.name = "ApiError";
        this.status = status;
        this.data = data;
    }
}

async function performApiRequest(endpoint: string, requestOptions: FetchOptions = {}): Promise<any> {
    const scopes = ["User.Read"];
    if (process.env.REACT_APP_USE_MOCK_AUTH === 'false') {
        const account = msalInstance.getAllAccounts()[0];
        if (!account) throw new Error("No user account found");

        let accessToken = await getToken(scopes, account);

        const headers = {
            ...requestOptions.headers,
            Authorization: `Bearer ${accessToken}`,
        };

        const axiosConfig = {
            ...requestOptions,
            headers,
        };

        try {
            return await axios(`${process.env.REACT_APP_BACKEND_URI}${endpoint}`, axiosConfig);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response && error.response.data.message === "Token expired") {
                console.error("Token expired. Attempting to refresh token...");
                
                accessToken = await getToken(scopes, account, true);
                
                axiosConfig.headers.Authorization = `Bearer ${accessToken}`;
                
                return await axios(`${process.env.REACT_APP_BACKEND_URI}${endpoint}`, axiosConfig);
            } else if (axios.isAxiosError(error)) {
                if (error.response) {
                    console.error("API Error:", error.response.status, error.response.data);
                    throw new ApiError(error.response.status, error.response.data.message || "Ocurrió un error.", error.response.data);
                } else {
                    console.error("Request Error:", error.message);
                    throw new ApiError(500, "Ocurrió un error", null);
                }
            }
        }
    }
    else {
        const user = selectMockUser();
        const headers: CustomHeaders = {
            ...requestOptions.headers,
            Authorization: `Bearer ${user.apiToken}`,
        };

        if (requestOptions.data instanceof FormData) {
            delete headers['Content-Type'];
        }

        const axiosConfig = {
            ...requestOptions,
            headers,
        };

        return await axios(`${process.env.REACT_APP_BACKEND_URI}${endpoint}`, axiosConfig);
    }
}

async function getToken(scopes: any, account: any, forceRefresh = false) {
    const silentRequest = {
        account,
        scopes,
        forceRefresh,
    };

    try {
        const response = await msalInstance.acquireTokenSilent(silentRequest);
        return response.idToken;
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            const interactiveResponse = await msalInstance.acquireTokenPopup({ scopes });
            return interactiveResponse.idToken;
        } else {
            throw error;
        }
    }
}

const selectMockUser = () => {
  const id = 7;
  const user = mockAccounts.find((account) => account.id === id);
  if (!user) {
    throw new Error("Mock user not found");
  }
  return user;
};

export default performApiRequest;