import React, { useState } from 'react';
import { MapContainer, TileLayer, Polyline, Popup, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import performApiRequest from './performApiRequest';
import { API_ENDPOINTS } from './endpoints';
import L from 'leaflet';
import { useToast } from '../context/useToast';
import formatDatetimeStringToDDMMYYYY from './formatDatetimeStringToDDMMYYYY';
import './arrow.css';

interface LocationData {
    latitud: number;
    longitud: number;
}

interface VehicleTrackerProps {
    muestra: string;
    message: string;
}

const createArrowIcon = (orientation: number) => {
    const icon = L.divIcon({
        html: `<div style="transform: rotate(${orientation}deg);"><ArrowUpwardIcon /></div>`,
        iconSize: [20, 20],
        iconAnchor: [10, 10]
    });
    return icon;
};

const VehicleTracker: React.FC<VehicleTrackerProps> = ({ muestra, message }) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [trajectoryData, setTrajectoryData] = useState<any>([]);
    const { showErrorToast } = useToast();

    const handleStartDateChange = (event: any) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event: any) => {
        setEndDate(event.target.value);
    };

    const fetchTrajectoryData = async () => {
        try {
            const requestOptions = {
                method: 'POST',
                data: {
                    muestra,
                    fecha: startDate,
                    fecha2: endDate  
                }
            }
            const response = await performApiRequest(
                API_ENDPOINTS.obtainVehicleTrajectory,
                requestOptions
            )

            if (response.status === 200) {
                setTrajectoryData(response.data[0].mensaje);
            }

            else if (response.status !== 200) {
                showErrorToast('El vehículo no se encuentra en la base de datos del proveedor de GPS.')
            }

        } catch (error: any) {
            showErrorToast(`Error: ${error.message}`);
        }
    };

    
    return (
        <div className="mt-5 mb-5 flex flex-col items-center">
            <h1 className="font-semibold text-md mb-5">{message}</h1>
            <div>
                <label>Desde: </label>
                <input type="date" value={startDate} onChange={handleStartDateChange} max={endDate} />
            </div>
            <div>
                <label>Hasta: </label>
                <input type="date" value={endDate} onChange={handleEndDateChange} min={startDate} />
            </div>
            <button className="mt-5 mb-5 bg-blue-600 hover:bg-blue-800 rounded-sm py-1 px-2 text-white"onClick={fetchTrajectoryData}>Ver trayectoria en el mapa</button>
            {(trajectoryData && trajectoryData.length > 0) && (
                <MapContainer center={[-33.45694, -70.64827]} zoom={13} style={{ height: '400px', width: '100%' }}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Polyline positions={trajectoryData.map((point: LocationData) => [point.latitud, point.longitud])}>
                        {trajectoryData.filter((data: any) => data.direccion !== "").map((point: any, index: number) => (
                            index < trajectoryData.length - 1 &&
                            <Marker key={index} position={[point.latitud, point.longitud]} icon={createArrowIcon(point.orientation)}>
                                <Popup>
                                    <p>Dirección: {point.direccion}</p>
                                    <p>Conductor: {point.conductor}</p>
                                    {(point.ibutton != null) && (<p>iButton: {point.ibutton}</p>)}
                                    <p>Fecha: {formatDatetimeStringToDDMMYYYY(point.evfecha)}</p>
                                </Popup>
                            </Marker>
                        ))}
                    </Polyline>
                </MapContainer>
            )}
        </div>
    );
};

export default VehicleTracker;