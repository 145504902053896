function debounce<F extends (...args: any[]) => Promise<any>>(func: F, waitFor: number) {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    return function(...args: Parameters<F>): Promise<ReturnType<F>> {
        return new Promise((resolve, reject) => {
            const delayedFuncCall = () => {
                try {
                    resolve(func(...args));
                } catch (error) {
                    reject(error);
                }
            };

            if (timeout !== null) {
                clearTimeout(timeout);
            }

            timeout = setTimeout(delayedFuncCall, waitFor);
        });
    };
}

export default debounce;