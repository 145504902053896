import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

interface TableColumn {
    key: string;
    label: string;
}
  
interface TableProps {
    title: string;
    data: any;
    renderRow: (row: any, index: number) => JSX.Element;
    toggleVisibility: () => void;
    isVisible: boolean;
    headers: string[] | TableColumn[];
}

const ReusableTable: React.FC<TableProps> = ({ title, data, renderRow, toggleVisibility, isVisible, headers }) => (
    <div>
      <h1 className="text-md font-semibold mb-5 cursor-pointer" onClick={toggleVisibility}>
        <span className={`inline-block mr-2 transform transition-transform duration-200 ${isVisible ? 'rotate-45 translate-y-0.5' : ''}`}>
          ▶︎
        </span>
        {title}
      </h1>
      {isVisible && (
        data.length > 0 ? (
          <div className="overflow-x-auto">
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {headers.map(header => typeof header === 'string' ? (
                      <TableCell key={header} align="center">{header}</TableCell>
                    ) : (
                      <TableCell key={header.key} align="center">{header.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map(renderRow)}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : <div className="text-center font-semibold">Aún no hay datos disponibles para estas fechas.</div>
      )}
    </div>
);

export default ReusableTable;