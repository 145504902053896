import Layout from "../../components/layOutWrapper/layOutWrapper";
import { ShowAllExpenseReportRequests } from "../../components/expenseReportRequests/showAllExpenseReportRequests";

export default function AllExpenseReportRequestsView () {
    return (
        <Layout 
            children={<ShowAllExpenseReportRequests {... {endpointURL: `/v1/expenseReportRequestController/allExpenseReportRequests`}}/>} 
            sectionTitle="Todas las solicitudes de rendición de gastos"
        />
    )
}