import Layout from "../../components/layOutWrapper/layOutWrapper";
import TravelAllowanceRequestForm from "../../components/travelAllowanceRequests/travelAllowanceRequestForm";

export default function ModifyTravelAllowanceRequestFormView (props: any) {
    const { requestData, requestDetails } = props;
    return (
        <>
            <Layout children={<TravelAllowanceRequestForm requestData={requestData} 
                requestDetails={requestDetails}/>} sectionTitle="Modificar solicitud"/>
        </>
    )
}