import React, { useState, useEffect, useCallback, useRef } from 'react'
import 'dayjs/locale/es';
import { useAuthStatus } from '../../hooks/useAuthStatus';
import BlockScreen from '../../components/blockScreen/blockScreen';
import { useNavigate } from 'react-router-dom';
import Loader from '../../elements/loader';
import { TravelAllowanceRequestToShow } from '../../types/fields';
import performApiRequest from '../../utils/performApiRequest';
import Select, { components } from 'react-select';
import Layout from '../../components/layOutWrapper/layOutWrapper';

interface OptionType {
  label: string;
  value: string;
}

const entityTypeOptions: OptionType[] = [
  { value: 'Vehículo', label: 'Vehículo' },
  { value: 'Solicitud de viático', label: 'Viático' },
  { value: 'Archivo de justificación de solicitud de viático', label: 'Archivo - Solicitud de viático' },
  { value: 'Solicitud de rendición de gastos', label: 'Rendición de gastos' },
  { value: 'Archivo de respaldo de rendición de gastos', label: 'Archivo - Solicitud de rendición de gastos' },
  { value: 'Noticia', label: 'Noticias' },
];

const CustomOption = (props: any) => {
  return (
    <components.Option {...props}>
      {props.isSelected ? <span>✔️ </span> : null}
      {props.label}
    </components.Option>
  );
};

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    maxHeight: '10%',
    overflow: 'hidden'
  }),
  multiValue: (provided: any) => ({
    ...provided,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    flexWrap: 'nowrap',
    overflow: 'auto'
  }),
};

export default function TravelAllowanceStatisticsView () {
  return (
      <Layout sectionTitle="Historial de acciones">
          <AllActionsTable/>
      </Layout>
  )
}

const AllActionsTable: React.FC = () => {
    const isAuthenticated = useAuthStatus();
    const [actions, setActions] = useState<TravelAllowanceRequestToShow[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [rut, setRut] = useState('');
    const [lastName, setLastName] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();
    const [selectedEntityType, setSelectedEntityType] = useState<string[]>([]);
    
    const selectedEntityTypeOptions = entityTypeOptions.filter(option =>
      selectedEntityType.includes(option.value)
    );

    const fetchActions = useCallback(async () => {
      setIsLoading(true);
      try {
          const queryParams = new URLSearchParams({
              rut,
              lastName,
              page: currentPage.toString(),
              limit: '10',
          });

          selectedEntityType.forEach(type => {
            queryParams.append('entityType', type);
          });

          const endpoint = `/v1/allActionsMonitor/getAllActions?${queryParams.toString()}`;
          const requestOptions = {
            method: 'GET',
            'Content-Type': 'application/json',
          };
          if (isAuthenticated) {
            const response = await performApiRequest(endpoint, requestOptions);
            setActions(response.data.requests);
            setTotalPages(response.data.totalPages || 1);
          }
      } catch (error) {
          console.error('There was an error fetching the Travel Allowance requests:', error);
      } finally {
          setIsLoading(false);
      }
  }, [rut, lastName, selectedEntityType, currentPage, isAuthenticated]);

  const fetchActionsRef = useRef(fetchActions);

  useEffect(() => {
    fetchActionsRef.current = fetchActions;
  }, [fetchActions]);

  useEffect(() => {
      if (isAuthenticated) {
          fetchActionsRef.current();
      }
  }, [isAuthenticated]);

  useEffect(() => {
    fetchActionsRef.current();
  }, [currentPage]);

    const handleClick = (request_id: number) => {
        navigate(`/particularTravelAllowanceRequest/${request_id}`);
    }

    if (!isAuthenticated) {
        return <BlockScreen/>
    }

    if (isLoading) {
        return <Loader/>
    }

    const handlePageChange = (newPage: number) => {
      setCurrentPage(newPage);
      //fetchActions();
  };
  

    return (
        <div className="grid grid-cols-1 justify-center items-center p-4 mb-10">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
            <input
              type="text"
              placeholder="RUT del funcionario..."
              value={rut}
              onChange={(e) => setRut(e.target.value)}
              className="rounded-lg px-2 py-1"
            />
            <input
              type="text"
              placeholder="Apellido del funcionario..."
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="rounded-lg px-2 py-1"
            />
            <Select
              isMulti
              options={entityTypeOptions}
              styles={customStyles}
              components={{ Option: CustomOption }}
              classNamePrefix="select"
              placeholder="Objeto de búsqueda..."
              value={selectedEntityTypeOptions}
              onChange={(selectedOptions) => {
                const values = selectedOptions.map(option => option.value);
                setSelectedEntityType(values);
              }}
            />
            <button 
              onClick={() => fetchActions()}
              className="rounded-lg bg-blue-600 hover:bg-blue-800 px-2 py-1 text-white ml-5">
                Buscar
            </button>
          </div>
          <div className="overflow-x-auto rounded mb-4">
            <h2 className="py-3 text-gray-700 text-md font-semibold mb-10">Selecciona el número de una solicitud para revisarla en detalle: </h2> 
            <table className="min-w-full bg-sectionBarBackgroundColor shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <thead className="bg-sectionBarBackgroundColor">
                <tr>
                  <th className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2"></th>
                  <th className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">RUT del funcionario</th>
                  <th className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">Funcionario</th>
                  <th className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">Entidad</th>
                  <th className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">Detalles</th>
                  <th className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">Fecha</th>
                </tr>
              </thead>
              {(actions.length > 0) ? (
                <tbody className="text-gray-700">
                  {actions.map((action: any) => (
                    <tr key={action.id} className="border-b">
                      <td className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">
                        <button className="text-customBlue hover:text-blue-800" onClick={() => handleClick(action.id)}>
                          {action.id}
                        </button>
                      </td>
                      <td className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">{action.userData.rut}-{action.userData.dv_rut}</td>
                      <td className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">{action.userData.names} {action.userData.ap_pat}</td>
                      <td className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">{action.action_type} {action.entity_type} </td>
                      <td className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">{action.details}</td>
                      <td className="py-3 px-6 text-center text-gray-700 text-md font-semibold mb-2">{action.action_datetime} </td>
                    </tr>
                  ))}
                  </tbody>
                ) :
                ( null )
              }
            </table>
            {actions.length === 0 && (
              <div className="text-center grey-800 font-semibold font-custom"> No se encontraron acciones.</div>
            )}
        </div>
        <div className="grid grid-cols-2 md:grid-cols-5 gap-5 items-center">
          <button
            className={`cursor-pointer px-2 py-1 bg-blue-400 text-white rounded-md ${currentPage === 1 ? 'invisible' : 'visible'}`}
            onClick={() => handlePageChange(1)}
          >
            1
          </button>
          <button
            className={`cursor-pointer px-1 py-1 bg-blue-400 text-white rounded-md ${currentPage === 1 ? 'invisible' : 'visible'}`}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Anterior
          </button>
          {/* This div remains centered as other elements take up space even when invisible */}
          <div className="px-1 py-1 rounded-md text-center font-semibold text-center col-start-2 md:col-start-3 col-span-3 md:col-span-1">Página {currentPage} de {totalPages}</div>
          <button
            className={`cursor-pointer px-1 py-1 bg-blue-400 text-white rounded-md ${currentPage === totalPages ? 'invisible' : 'visible'}`}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Siguiente
          </button>
          <button
            className={`cursor-pointer px-1 py-1 bg-blue-400 text-white rounded-md ${currentPage === totalPages ? 'invisible' : 'visible'}`}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </button>
        </div>
    </div>
  )
}