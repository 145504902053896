import React, { createContext, ReactNode, useContext, useCallback } from 'react';
import { toast, ToastOptions } from 'react-toastify';

// Define the type for the context value
type ToastContextType = {
  showSuccessToast: (message: string, options?: ToastOptions) => void;
  showErrorToast: (message: string, options?: ToastOptions) => void;
};

// Create the context with a default empty implementation to satisfy the type
const ToastContext = createContext<ToastContextType>({
  showSuccessToast: () => {},
  showErrorToast: () => {},
});

// Create a custom hook for using the toast context
export const useToast = () => useContext(ToastContext);

// Define the type for the ToastProvider's props
type ToastProviderProps = {
  children: ReactNode;
};

// Implement the ToastProvider component
export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const showSuccessToast = useCallback((message: string, options?: ToastOptions) => {
    toast.success(message, options);
  }, []);

  const showErrorToast = useCallback((message: string, options?: ToastOptions) => {
    toast.error(message, options);
  }, []);

  return (
    <ToastContext.Provider value={{ showSuccessToast, showErrorToast }}>
      {children}
    </ToastContext.Provider>
  );
};