import React, { useState, useEffect } from 'react';
import performApiRequest from '../../../utils/performApiRequest';
import { useAuthStatus } from '../../../hooks/useAuthStatus';
import Loader from '../../../elements/loader';
import 'react-datepicker/dist/react-datepicker.css';
import Layout from '../../../components/layOutWrapper/layOutWrapper';
import { API_ENDPOINTS } from '../../../utils/endpoints';
import { useNavigate } from 'react-router-dom';
import { FIELD_OPTIONS_ENDPOINTS } from '../../../utils/fieldOptionsEndpoints';
import { useToast } from '../../../context/useToast';
import { useIsAuthenticated } from '@azure/msal-react';

export default function CategoriesAdministratorView () {
    return (
        <Layout 
            children={<CategoriesAdministratorPanel/>} 
            sectionTitle="Administra las categorías de gastos"
        />
    )
}

export function CategoriesAdministratorPanel() {
    const isAuthenticated = useAuthStatus();
    const [categories, setCategories] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [editCategory, setEditCategory] = useState<any>(null);
    const { showSuccessToast, showErrorToast } = useToast();
    const navigate = useNavigate();

    useEffect(() => {
      if (isAuthenticated) {
        fetchCategories();
      }
    }, [isAuthenticated]);

    const fetchCategories = async () => {
      try {
        const requestOptions = { method: 'GET' };
        const response = await performApiRequest(API_ENDPOINTS.getExpenseCategories, requestOptions);
        console.log(response);
        if (response.status === 200 && Array.isArray(response.data)) {
          setCategories(response.data);
          setIsLoading(false);
        } else {
          setCategories([]);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error fetching bill accounts:', error);
      }
    };

    const handleActivateCategory = async (id: number) => {
      try {
        const response = await performApiRequest(`${API_ENDPOINTS.activateExpenseCategory}/${id}`, {
          method: 'PATCH'
        });
        if (response.status === 204) {
          showSuccessToast('La cuenta fue activada correctamente.');
          navigate('/expenseReportSystemAdministration')
        }
      } catch (error: any) {
        showErrorToast(`Error: ${error.message}`);
      }
    };

    const handleDeactivateCategory = async (id: number) => {
      try {
        const response = await performApiRequest(`${API_ENDPOINTS.deactivateExpenseCategory}/${id}`, {
          method: 'PATCH'
        });
        if (response.status === 204) {
          showSuccessToast('La cuenta fue desactivada correctamente.');
          navigate('/expenseReportSystemAdministration')
        }
      } catch (error: any) {
        showErrorToast(`Error: ${error.message}`);
      }
    };

    const handleEditCategory = (category: any) => {
      setEditCategory(category);
    };

    const handleCategoryUpdated = () => {
      fetchCategories();
    };

    if (isLoading || !categories) return <Loader />;
  
    return (
      <div>
        {editCategory ? (
          <EditCategoryForm 
            category={editCategory} 
            onClose={() => setEditCategory(null)} 
            onCategoryUpdated={handleCategoryUpdated} 
          />
        ) : (
          <>
          <AddCategoryForm/>
          <div className="mt-8 mb-4 font-bold text-lg"> Categorías de gastos </div>
                {categories.map((category: any) => (
                  <div key={category.id} className="grid grid-cols-4 bg-blue-200 py-2 px-4 mb-2 rounded">
                    <div>
                      {category.name} - {category.description}.
                    </div>
                    <div> 
                      {category.BillAccount ? (
                        <div>
                          Cuenta contable: {category.BillAccount.account_number} - {category.BillAccount.description} 
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <button
                        onClick={() => handleEditCategory(category)}
                        className="bg-yellow-500 text-white py-1 px-2 rounded ml-2"> 
                          Editar categoría
                      </button>
                    </div>
                    <div>
                      {category.is_active ? (
                        <div>
                          <button 
                            onClick={() => {
                              if (category.id) {
                                handleDeactivateCategory(category.id);
                              }
                            }}
                            className="bg-red-500 text-white py-1 px-2 rounded ml-2">Desactivar categoría</button>
                        </div>
                      ) : (
                        <div>
                          <button
                            onClick={() => {
                              if (category.id) {
                                handleActivateCategory(category.id);
                              }
                            }}
                            className="bg-blue-500 text-white py-1 px-2 rounded ml-2">Activar categoría</button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                {categories.length === 0 && (
                  <div> Aún no hay categorías creadas. </div>
                )}
          </>
        )}
      </div>
    );
}

const AddCategoryForm = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [billAccountId, setBillAccountId] = useState('');
  const [billAccounts, setBillAccounts] = useState([]);
  const { showSuccessToast, showErrorToast } = useToast();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate()

  // Fetch bill account options
  useEffect(() => {
    const fetchBillAccounts = async () => {
      try {
        const requestOptions = { method: 'GET' };
        const response = await performApiRequest(FIELD_OPTIONS_ENDPOINTS.bill_accounts, requestOptions);
        if (response.status === 200) {
          setBillAccounts(response.data);
        }
      } catch (error) {
        console.error('Error fetching bill accounts:', error);
      }
    };
    if (isAuthenticated) {
      fetchBillAccounts();
    }
  }, [isAuthenticated]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const newCategory = {
      name,
      description,
      bill_account_id: billAccountId,
    };

    const requestOptions = {
      method: 'POST',
      data: newCategory
    };

    try {
      const response = await performApiRequest(API_ENDPOINTS.createExpenseCategory, requestOptions);

      if (response.status === 201) {
        showSuccessToast('Categoría creada correctamente');
        setName('');
        setDescription('');
        setBillAccountId('');
        navigate('/expenseReportSystemAdministration');
      }
    } catch (error) {
      console.error('Error adding category:', error);
      showErrorToast('Error al agregar categoría');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="bg-blue-200 p-4 rounded">
      <div className="mb-4">
        <div className="font-bold text-center"> Crea una nueva categoría de gastos </div>
        <label className="block text-gray-700">Nombre:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full px-2 py-1 border rounded"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Descripción:</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="w-full px-2 py-1 border rounded"
          required
        ></textarea>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Cuenta contable:</label>
        <select
          value={billAccountId}
          onChange={(e) => setBillAccountId(e.target.value)}
          className="w-full px-2 py-1 border rounded"
          required
        >
          <option value="">¿A qué cuenta contable pertenece la categoría?</option>
          {billAccounts.map((account: any) => (
            <option key={account.id} value={account.account_number}>
              {account.account_number} - {account.description}
            </option>
          ))}
        </select>
      </div>
      <div>
        <button
          type="submit"
          className="bg-green-500 text-white py-1 px-4 rounded"
        >
          Agregar categoría
        </button>
      </div>
    </form>
  );
};

interface EditCategoryFormProps {
  category: any;
  onClose: () => void;
  onCategoryUpdated: () => void;
}

const EditCategoryForm: React.FC<EditCategoryFormProps> = ({ category, onClose, onCategoryUpdated }) => {
  const [name, setName] = useState(category.name);
  const [description, setDescription] = useState(category.description);
  const [billAccountId, setBillAccountId] = useState(category.bill_account_id);
  const [billAccounts, setBillAccounts] = useState([]);
  const { showSuccessToast, showErrorToast } = useToast();

  useEffect(() => {
    const fetchBillAccounts = async () => {
      try {
        const requestOptions = { method: 'GET' };
        const response = await performApiRequest(FIELD_OPTIONS_ENDPOINTS.bill_accounts, requestOptions);
        if (response.status === 200) {
          setBillAccounts(response.data);
        }
      } catch (error) {
        console.error('Error fetching bill accounts:', error);
      }
    };
    fetchBillAccounts();
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const updatedCategory = {
      name,
      description,
      bill_account_id: billAccountId,
    };
    console.log(updatedCategory);

    const requestOptions = {
      method: 'PATCH',
      data: updatedCategory
    };

    try {
      const response = await performApiRequest(`${API_ENDPOINTS.editExpenseCategory}/${category.id}`, requestOptions);
      if (response.status === 204) {
        showSuccessToast('Categoría actualizada correctamente');
        onCategoryUpdated();
        onClose();
      }
    } catch (error) {
      console.error('Error updating category:', error);
      showErrorToast('Error al actualizar categoría');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="bg-blue-200 p-4 rounded">
      <div className="mb-4">
        <label className="block text-gray-700">Nombre:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full px-2 py-1 border rounded"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Descripción:</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="w-full px-2 py-1 border rounded"
          required
        ></textarea>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Cuenta contable:</label>
        <select
          value={billAccountId}
          onChange={(e) => setBillAccountId(e.target.value)}
          className="w-full px-2 py-1 border rounded"
          required
        >
          <option value="">¿A qué cuenta contable pertenece la categoría?</option>
          {billAccounts.map((account: any) => (
            <option key={account.id} value={account.account_number}>
              {account.account_number} - {account.description}
            </option>
          ))}
        </select>
      </div>
      <div>
        <button
          type="submit"
          className="bg-green-500 text-white py-1 px-4 rounded"
        >
          Actualizar categoría
        </button>
        <button
          type="button"
          onClick={onClose}
          className="bg-red-500 text-white py-1 px-4 rounded ml-2"
        >
          Cancelar
        </button>
      </div>
    </form>
  );
};