import Layout from "../../../components/layOutWrapper/layOutWrapper";
import 'react-calendar/dist/Calendar.css';
import { useNavigate } from "react-router-dom";
import 'dayjs/locale/es';
import ForwardIcon from '@mui/icons-material/Forward';
import { useIsAuthenticated } from '@azure/msal-react';
import BlockScreen from "../../../components/blockScreen/blockScreen";

export default function ExpenseSystemAdministratorView () {
    return (
        <Layout sectionTitle="Administración del sistema de rendición de gastos">
            <ExpenseSystemAdministratorPanel />
        </Layout>
    )
}

const ExpenseSystemAdministratorPanel: React.FC = () => {
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();

    if (!isAuthenticated) {
      return <BlockScreen/>
    }

    return (
      <div>
        <div className="flex flex-col md:flex-row justify-between">
          <div className="flex flex-col">
            <h1 className="text-xl font-semibold mb-5">Administra el sistema de rendición de gastos</h1>
            <div className="flex flex-col gap-4">
              <h2 className="text-md mb-5"> Puedes realizar las siguientes acciones: </h2>
              <div className="flex flex-row align-center"> 
                <button className="bg-transparent text-sm" onClick={() => navigate('/expenseUnitsAdministration')}>
                  <ForwardIcon fontSize={"small"}/>
                  Administrar rendidores y aprobadores de una unidad.
                </button>
              </div>
              <div className="flex flex-row align-center"> 
                <button className="bg-transparent text-sm" onClick={() => navigate('/expenseCategoriesAdministration')}>
                  <ForwardIcon fontSize={"small"}/>
                  Administrar categorías de gastos.
                </button>
              </div>
              <div className="flex flex-row align-center"> 
                <button className="bg-transparent text-sm" onClick={() => navigate('/expenseBillAccountsAdministration')}>
                  <ForwardIcon fontSize={"small"}/>
                  Administrar cuentas contables.
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };