import React, { useState, useEffect } from 'react';
import performApiRequest from '../../../utils/performApiRequest';
import { useAuthStatus } from '../../../hooks/useAuthStatus';
import { useDataForEndpoint } from '../../../context/dataProvider';
import Loader from '../../../elements/loader';
import 'react-datepicker/dist/react-datepicker.css';
import Layout from '../../../components/layOutWrapper/layOutWrapper';
import { API_ENDPOINTS } from '../../../utils/endpoints';
import { useToast } from '../../../context/useToast';
import { useNavigate } from 'react-router-dom';
import { FIELD_OPTIONS_ENDPOINTS } from '../../../utils/fieldOptionsEndpoints';

interface AccountType {
  id: number;
  account_type_id: number
  account_name: string;
}

interface BillAccount {
    id?: number;
    account_number: number;
    description: string;
    account_type_id: number;
    account_type?: {[key: string]: any};
    is_active: boolean
}
  
interface BillAccountFormProps {
    onAdd: (newAccount: BillAccount) => void;
}

export default function BillAccountsAdministratorView () {
    return (
        <Layout 
            children={<BillAccountsAdministratorPanel/>} 
            sectionTitle="Administra las cuentas contables"
        />
    )
}

export function BillAccountsAdministratorPanel() {
      const isAuthenticated = useAuthStatus();
      const [billAccounts, setBillAccounts] = useState([]);
      const [isLoading, setIsLoading] = useState(true);
      const [groupedAccounts, setGroupedAccounts] = useState<Record<number, BillAccount[]>>({});
      const [editAccount, setEditAccount] = useState<BillAccount | null>(null);
      const { showSuccessToast, showErrorToast } = useToast();
      const navigate = useNavigate();
    
      useEffect(() => {
        fetchBillAccounts();
      }, []);

      const fetchBillAccounts = async () => {
        try {
          const requestOptions = { method: 'GET' };
          const response = await performApiRequest(FIELD_OPTIONS_ENDPOINTS.bill_accounts, requestOptions);
          if (response.status === 200) {
            setBillAccounts(response.data);
          }
        } catch (error) {
          console.error('Error fetching bill accounts:', error);
        }
      };

		useEffect(() => {
			if (isAuthenticated && billAccounts && Array.isArray(billAccounts)) {
				setIsLoading(false);
				groupBillAccounts(billAccounts);
			} else {
				setIsLoading(true);
			} // eslint-disable-next-line
		}, [billAccounts, isAuthenticated]);
    
		const groupBillAccounts = (accounts: BillAccount[]) => {
			if (!accounts) {
				return;
			}
			const grouped = accounts.reduce((acc, account) => {
				const typeId = account.account_type_id;
				acc[typeId] = acc[typeId] || [];
				acc[typeId].push(account);
				return acc;
			}, {} as Record<number, BillAccount[]>);
			setGroupedAccounts(grouped);
		};

    const handleAddBillAccount = async (newAccount: any) => {
        try {
          const response = await performApiRequest(API_ENDPOINTS.createBillAccount, {
            method: 'POST',
            data: newAccount
          });
          if (response.status === 201) {
            showSuccessToast('La cuenta fue creada correctamente.');
            navigate('/expenseReportSystemAdministration')
          }
        } catch (error: any) {
          showErrorToast(`Error: ${error.message}`);
        }
    };

    const handleActivateBillAccount = async (id: number) => {
      try {
        const response = await performApiRequest(`${API_ENDPOINTS.activateBillAccount}/${id}`, {
          method: 'PATCH'
        });
        if (response.status === 204) {
          showSuccessToast('La cuenta fue activada correctamente.');
          navigate('/expenseReportSystemAdministration')
        }
      } catch (error: any) {
        showErrorToast(`Error: ${error.message}`);
      }
    };

    const handleDeactivateBillAccount = async (id: number) => {
      try {
        const response = await performApiRequest(`${API_ENDPOINTS.deactivateBillAccount}/${id}`, {
          method: 'PATCH'
        });
        if (response.status === 204) {
          showSuccessToast('La cuenta fue desactivada correctamente.');
          navigate('/expenseReportSystemAdministration')
        }
      } catch (error: any) {
        showErrorToast(`Error: ${error.message}`);
      }
    };

    const handleEditBillAccount = (account: BillAccount) => {
      setEditAccount(account);
    };
  
    const handleAccountUpdated = () => {
      fetchBillAccounts();
      if (billAccounts) {
        groupBillAccounts(billAccounts);
      }
      setEditAccount(null);
    };

    if (isLoading || !groupedAccounts) return <Loader />;
  
    return (
      <div>
        {editAccount ? (
          <EditBillAccountForm 
            account={editAccount} 
            onClose={() => setEditAccount(null)} 
            onAccountUpdated={handleAccountUpdated} 
          />
        ) : (
          <>
          <BillAccountForm onAdd={handleAddBillAccount} />
          <div className="mt-8 mb-4 font-bold text-lg"> Cuentas contables </div>
            {groupedAccounts && Object.entries(groupedAccounts).map(([typeId, accounts]) => (
              <div key={typeId} className="mb-5 mt-5">
                <h3 className="font-bold mb-5">
                  {accounts.length > 0 && accounts[0].account_type ? accounts[0].account_type.account_name : 'Unknown Account Type'}
                </h3>
                {accounts.map((account) => (
                  <div key={account.id} className="grid grid-cols-3 gap-4 bg-blue-200 py-2 px-4 mb-2 rounded items-center">
                    <div>
                      {account.account_number} - {account.description}
                    </div>
                    <div>
                      <button
                        onClick={() => handleEditBillAccount(account)}
                        className="bg-yellow-500 text-white py-1 px-2 rounded">
                        Editar cuenta contable
                      </button>
                    </div>
                    <div>
                      {account.is_active ? (
                        <div>
                          <button 
                            onClick={() => {
                              if (account.id) {
                                handleDeactivateBillAccount(account.id);
                              }
                            }}
                            className="bg-red-500 text-white py-1 px-2 rounded ml-2">Desactivar cuenta contable</button>
                        </div>
                      ) : (
                        <div>
                          <button
                            onClick={() => {
                              if (account.id) {
                                handleActivateBillAccount(account.id);
                              }
                            }}
                            className="bg-blue-500 text-white py-1 px-2 rounded ml-2">Activar cuenta contable</button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </>
        )}
      </div>
    );
}

function BillAccountForm({ onAdd }: BillAccountFormProps) {
    const [accountNumber, setAccountNumber] = useState<number | ''>('');
    const [description, setDescription] = useState<string>('');
    const [accountTypeId, setAccountTypeId] = useState<number | ''>('');
    const isAuthenticated = useAuthStatus();
    const [isLoading, setIsLoading] = useState(true);
    const accountTypes = useDataForEndpoint<AccountType[]>('account_types');

    useEffect(() => {
        const allData = [
          accountTypes,
        ];
        
        if (isAuthenticated) {
            const isAnyDataUndefined = allData.some(data => data === undefined);
            setIsLoading(isAnyDataUndefined);
        }
    }, [accountTypes, isAuthenticated]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (accountNumber === '' || accountTypeId === '') {
          alert('Rellena todos los campos.');
          return;
        }
        onAdd({ 
          account_number: Number(accountNumber), 
          description, 
          account_type_id: Number(accountTypeId),
          is_active: true
        });
        setAccountNumber('');
        setDescription('');
        setAccountTypeId('');
    };
    
    if (isLoading) {
        return <Loader/>
    }
  
    return (
      <form onSubmit={handleSubmit} className="w-full flex justify-center">
        <div className="border-2 rounded-xl mx-2 my-2 px-2 py-2 flex flex-col items-center">
          <div className="mt-4 mb-4 font-semibold text-center"> Agrega una nueva cuenta contable</div>
          <input
            type="number"
            value={accountNumber}
            onChange={(e) => setAccountNumber(Number(e.target.value))}
            placeholder="Número de cuenta"
            required
            className="rounded-md px-2 py-1 mb-2 w-full text-center"
          />
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Descripción"
            required
            className="rounded-md px-2 py-1 mb-2 w-full text-center"
          />
          <select 
            value={accountTypeId}
            onChange={(e) => setAccountTypeId(Number(e.target.value))} 
            className="rounded-md px-2 py-1 mb-2 w-full"
          >
            <option value="" className="text-center">Selecciona un tipo de cuenta:</option>          
            {accountTypes.map(account => (
              <option key={account.id} value={account.id}>
                {account.id} - {account.account_name}
              </option>
            ))}
          </select>
          <button type="submit" className="mt-5 rounded-md px-2 py-1 bg-blue-400 hover:bg-blue-600 text-white">
            Agregar cuenta contable
          </button>
        </div>
      </form>

    );
}

interface BillAccount {
  id?: number;
  account_number: number;
  description: string;
  account_type_id: number;
  account_type?: { [key: string]: any };
  is_active: boolean;
}

interface EditBillAccountFormProps {
  account: BillAccount;
  onClose: () => void;
  onAccountUpdated: () => void;
}

const EditBillAccountForm: React.FC<EditBillAccountFormProps> = ({ account, onClose, onAccountUpdated }) => {
  const [accountNumber, setAccountNumber] = useState(account.account_number);
  const [description, setDescription] = useState(account.description);
  const [accountTypeId, setAccountTypeId] = useState(account.account_type_id);
  // eslint-disable-next-line
  const [billAccounts, setBillAccounts] = useState([]);
  const { showSuccessToast, showErrorToast } = useToast();
  const isAuthenticated = useAuthStatus();
  const accountTypes = useDataForEndpoint<AccountType[]>('account_types');
  
  useEffect(() => {
    const fetchBillAccounts = async () => {
      try {
        const requestOptions = { method: 'GET' };
        const response = await performApiRequest(FIELD_OPTIONS_ENDPOINTS.bill_accounts, requestOptions);
        if (response.status === 200) {
          setBillAccounts(response.data);
        }
      } catch (error) {
        console.error('Error fetching bill accounts:', error);
      }
    };
    fetchBillAccounts();
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const updatedAccount = {
      account_number: accountNumber,
      description,
      account_type_id: accountTypeId,
      is_active: account.is_active,
    };

    const requestOptions = {
      method: 'PATCH',
      data: updatedAccount
    };

    try {
      const response = await performApiRequest(`${API_ENDPOINTS.editBillAccount}/${account.id}`, requestOptions);
      if (response.status === 204) {
        showSuccessToast('Cuenta contable actualizada correctamente');
        onAccountUpdated();
        onClose();
      }
    } catch (error) {
      console.error('Error updating account:', error);
      showErrorToast('Error al actualizar cuenta contable');
    }
  };

  if (isAuthenticated && !accountTypes) {
    return <Loader />;
  }

  return (
    <form onSubmit={handleSubmit} className="w-full flex justify-center">
      <div className="border-2 rounded-xl mx-2 my-2 px-2 py-2 flex flex-col items-center">
        <div className="mt-4 mb-4 font-semibold text-center"> Edita la cuenta contable</div>
        <input
          type="number"
          value={accountNumber}
          onChange={(e) => setAccountNumber(Number(e.target.value))}
          placeholder="Número de cuenta"
          required
          className="rounded-md px-2 py-1 mb-2 w-full"
        />
        <input
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Descripción"
          required
          className="rounded-md px-2 py-1 mb-2 w-full"
        />
        <select 
          value={accountTypeId}
          onChange={(e) => setAccountTypeId(Number(e.target.value))} 
          className="rounded-md px-2 py-1 mb-2 w-full"
        >
          <option value="" className="text-center">Selecciona un tipo de cuenta:</option>          
          {accountTypes.map(account => (
            <option key={account.id} value={account.id}>
              {account.id} - {account.account_name}
            </option>
          ))}
        </select>
        <div className="flex justify-center space-x-4">
          <button type="submit" className="mt-5 rounded-md px-2 py-1 bg-blue-400 hover:bg-blue-600 text-white">
            Actualizar cuenta contable
          </button>
          <button type="button" onClick={onClose} className="mt-5 rounded-md px-2 py-1 bg-red-400 hover:bg-red-600 text-white">
            Cancelar
          </button>
        </div>
      </div>
    </form>
  );
};
