import { useIsAuthenticated } from "@azure/msal-react";

const useMockIsAuthenticated = () => {
  return true;
};

export const useAuthStatus = () => {
  const isAuthenticated = useIsAuthenticated();
  const isMockAuthenticated = useMockIsAuthenticated();

  return process.env.REACT_APP_USE_MOCK_AUTH === 'false' ? isAuthenticated : isMockAuthenticated;
};