import React, { useState, useEffect } from 'react'
import { useAuthStatus } from '../../hooks/useAuthStatus';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { esES } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/es';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import BlockScreen from '../blockScreen/blockScreen';
import performApiRequest from '../../utils/performApiRequest';
import { useToast } from '../../context/useToast';
import { useDataForEndpoint } from '../../context/dataProvider';
import Loader from '../../elements/loader';

type VehicleType = {
    id: number;
    category_id: number;
    type_name: string;
};

type VehicleData = {
    [key: string]: any;
}

interface VehicleFormProps {
    vehicleData?: VehicleData;
}
  
const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    esES,
  );
  


const VehicleSchema = Yup.object().shape({
    vehicle_category: Yup.number().required('Debes seleccionar una categoría de vehículo.'),
    vehicle_type_id: Yup.number().required('Debes seleccionar un tipo de vehículo.'),
    vehicle_plate: Yup.string()
        .required('Debes ingresar la patente del vehículo.')
        .matches(/^.{4}-.{2}$/, 'La patente debe tener el formato ABCD-00.'),
    brand: Yup.string().required('Escribe la marca del vehículo.'),
    model: Yup.string().required('Agrega el modelo del vehículo'),
    version: Yup.string().required('Se requiere la versión del vehículo.'),
    fabrication_year: Yup.number().max(new Date().getFullYear(), 'El año no puede ser superior al año actual.').required('Selecciona el año de fabricación.'),
    acquisition_date: Yup.string().max(new Date().getFullYear(), 'El año no puede ser superior al año actual.').required('Selecciona el año de adquisición.'),
    vehicle_status_id: Yup.number().required('Agrega el estado del vehículo.'),
    description: Yup.string(),
    traction: Yup.string(),
    last_maintenance_date: Yup.string(),
    vehicle_image: Yup.mixed()
  });

const VehicleForm: React.FC<VehicleFormProps> = () => {
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    // const [selectedVehicleCategory, setSelectedVehicleCategory] = useState(0)
    // const [selectedCategoryName, setSelectedCategoryName] = useState<string>('');
    const vehicleTypes = useDataForEndpoint<VehicleType[]>('vehicle_types');
    const vehicleStatuses = useDataForEndpoint<{id: number; status_name: string}[]>('vehicle_statuses');
    const vehicleCategories = useDataForEndpoint<{ id: number; category_name: string }[]>('vehicle_categories');
    const [filteredVehicleTypes, setFilteredVehicleTypes] = useState<VehicleType[]>([]);
    const navigate = useNavigate();
    const location = useLocation();
    const vehicleData = location.state?.vehicleData;
    const [isLoading, setIsLoading] = useState(true);
    const { showSuccessToast } = useToast();
    const isAuthenticated = useAuthStatus();

    useEffect(() => {
        if (isAuthenticated) {
            const allData = [
                vehicleTypes,
                vehicleStatuses,
                vehicleCategories
            ];
              
            const isAnyDataUndefined = allData.some(data => data === undefined);
          
            setIsLoading(isAnyDataUndefined);
        }
    }, [vehicleTypes, vehicleStatuses, vehicleCategories, isAuthenticated]);
    

    useEffect(() => {
        if (isAuthenticated) {
            if (vehicleData && vehicleTypes) {
                console.log(vehicleData);
                const selected_category_id = vehicleData.vehicle_type.vehicle_category.id;
                const filteredTypes = vehicleTypes.filter(vehicle_type => vehicle_type.category_id === selected_category_id);
                setFilteredVehicleTypes(filteredTypes);
            }
        }
    }, [vehicleCategories, vehicleTypes, vehicleData, isAuthenticated]);

    const formik = useFormik({
        initialValues: {
            vehicle_category: vehicleData ? vehicleData.vehicle_type.vehicle_category.id ?? 1 : 0,
            vehicle_type_id: vehicleData ? vehicleData.vehicle_type.id : 1,
            vehicle_plate: vehicleData ? vehicleData.vehicle_plate : '',
            brand: vehicleData ? vehicleData.brand : '',
            model: vehicleData ? vehicleData.model : '',
            version: vehicleData ? vehicleData.version : '',
            fabrication_year: vehicleData ? vehicleData.fabrication_year : '',
            acquisition_date: vehicleData ? vehicleData.acquisition_date : '',
            vehicle_status_id: vehicleData ? vehicleData.vehicle_status_id : '',
            description: vehicleData ? vehicleData.description : '',
            traction: vehicleData ? vehicleData.traction : '',
            last_maintenance_date: vehicleData ? vehicleData.last_maintenance_date || '' : '',
            vehicle_image: vehicleData ? vehicleData.vehicle_image : ''
        },
        validationSchema: VehicleSchema,
        onSubmit: async (values: 
            {
                vehicle_category: number;
                vehicle_type_id: string;
                vehicle_plate: string;
                brand: string;
                model: string;
                version: string;
                fabrication_year: string;
                acquisition_date: string;
                vehicle_status_id: string;
                description: string;
                traction: string;
                last_maintenance_date: string | null;
                vehicle_image: string | Blob;
            }
            ) => {
            if (vehicleData?.id) {
                const updateVehicleEndpoint = `/v1/vehicleController/updateVehicle/${vehicleData.id}`;
                try {
                    const formData = new FormData();
                    if (values.vehicle_image instanceof Blob) {
                        formData.append('vehicle_image', values.vehicle_image);
                    }
                    Object.entries(values).forEach(([key, value]) => {
                        if (key !== 'vehicle_image') {
                            formData.append(key, value as string);
                        }
                    });
                    const requestOptions = {
                        method: 'PATCH',
                        data: formData
                    };
                    const response = await performApiRequest(updateVehicleEndpoint, requestOptions);
                    if (response.status === 200) {
                        showSuccessToast('Vehículo modificado con éxito.');
                        navigate('/allVehicles');
                    }
                } catch (error) {
                    console.error("Failed to fetch: ", error);
                }
            }
            else {
                const endpoint = `/v1/vehicleController/createVehicle`;
                try {
                    const formData = new FormData();
                    if (values.vehicle_image instanceof Blob) {
                        formData.append('vehicle_image', values.vehicle_image);
                    }
                    Object.entries(values).forEach(([key, value]) => {
                        if (key !== 'vehicle_image') {
                            formData.append(key, value as string);
                        }
                    });
                    
                    const requestOptions = {
                        method: 'POST',
                        data: formData
                    };

                    const response = await performApiRequest(endpoint, requestOptions);
                    
                    if (response.status === 201) {
                        showSuccessToast('Vehículo agregado exitosamente.');
                        navigate('/allVehicles');
                    }
                } catch (error) {
                    console.error("Failed to fetch: ", error);
                }
            }
            
        },
    });

    const handleDateChange = (date: Dayjs | null) => {
        const formattedDate = date ? date.format('YYYY-MM-DD') : null;
        formik.setFieldValue('last_maintenance_date', formattedDate);
    };

    const handleAcquisitionDateChange = (date: Dayjs | null) => {
        const formattedDate = date ? date.format('YYYY-MM-DD') : '';
        formik.setFieldValue('acquisition_date', formattedDate);
    };

    const handleCheckboxChange = (event: any) => {
        setIsCheckboxChecked(event.target.checked);
    };

    const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedCategoryId = parseInt(event.target.value);
        // const selectedCategory = vehicleCategories.find(category => category.id === selectedCategoryId);
        // if (selectedCategory) {
        //     setSelectedCategoryName(selectedCategory.category_name);
        // }
        // setSelectedVehicleCategory(selectedCategoryId);
        if (vehicleTypes) {
            const filteredTypes = vehicleTypes.filter(vehicle_type => vehicle_type.category_id === selectedCategoryId);
            setFilteredVehicleTypes(filteredTypes);
        }
    };

    if (!isAuthenticated) {
        return <BlockScreen/>
    }

    if (isLoading) {
        return <Loader message={"Cargando la información..."}/>
    }

    return (
        <form className="bg-sectionBarBackgroundColor shadow-md rounded px-8 pt-6 pb-8 mb-4" encType="multipart/form-data" onSubmit={formik.handleSubmit}>
            <div className="mb-6">
                <h2 className="text-gray-700 text-lg font-bold mb-4">Datos del vehículo</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="vehicle_category">
                        Categoría de vehículo
                    </label>
                    <select
                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="vehicle_category"
                        name="vehicle_category"
                        value={formik.values.vehicle_category}
                        onChange={(event) => {
                            handleCategoryChange(event);
                            formik.handleChange(event);
                        }}
                        onBlur={formik.handleBlur}
                    >
                        <option value="">Seleccione una categoría de vehículo</option>
                        {vehicleCategories.map((category: any) => (
                            <option key={category.id} value={category.id}>
                                {category.category_name}
                            </option>
                        ))}
                    </select>
                    {formik.touched.vehicle_category && formik.errors.vehicle_category ? (
                        <div className="text-red-500 text-sm">{formik.errors.vehicle_category}</div>
                    ) : null}
                </div>
                
                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="vehicle_type_id">
                        Tipo de vehículo <span className="italic">(requerido)</span>
                    </label>
                    <select
                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="vehicle_type_id"
                        name="vehicle_type_id"
                        value={formik.values.vehicle_type_id}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    >
                        <option value="">Seleccione un tipo de vehículo</option>
                        {filteredVehicleTypes.map((type: any) => (
                            <option key={type.id} value={type.id}>
                                {type.type_name}
                            </option>
                        ))}
                    </select>
                    {formik.touched.vehicle_type_id && formik.errors.vehicle_type_id ? (
                        <div className="text-red-500 text-sm">{formik.errors.vehicle_type_id}</div>
                    ) : null}
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="vehicle_plate">
                        Patente <span className="italic">(requerido)</span>
                    </label>
                    <input
                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="vehicle_plate"
                        name="vehicle_plate"
                        value={formik.values.vehicle_plate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />

                    {formik.touched.vehicle_plate && formik.errors.vehicle_plate ? (
                        <div className="text-red-500 text-sm">{formik.errors.vehicle_plate}</div>
                    ) : null}
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="brand">
                        Marca <span className="italic">(requerido)</span>
                    </label>
                    <input
                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="brand"
                        name="brand"
                        value={formik.values.brand}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />

                    {formik.touched.brand && formik.errors.brand ? (
                        <div className="text-red-500 text-sm">{formik.errors.brand}</div>
                    ) : null}
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="model">
                        Modelo <span className="italic">(requerido)</span>
                    </label>
                    <input
                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="model"
                        name="model"
                        value={formik.values.model}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />

                    {formik.touched.model && formik.errors.model ? (
                        <div className="text-red-500 text-sm">{formik.errors.model}</div>
                    ) : null}
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="version">
                        Versión <span className="italic">(requerido)</span>
                    </label>
                    <input
                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="version"
                        name="version"
                        value={formik.values.version}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />

                    {formik.touched.version && formik.errors.version ? (
                        <div className="text-red-500 text-sm">{formik.errors.version}</div>
                    ) : null}
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="vehicle_status_id">
                        Estado del vehículo <span className="italic">(requerido)</span>
                    </label>
                    <select
                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="vehicle_status_id"
                        name="vehicle_status_id"
                        value={formik.values.vehicle_status_id}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    >
                        <option value="">Seleccione un estado</option>
                        {vehicleStatuses.map((status: any) => (
                            <option key={status.id} value={status.id}>
                                {status.status_name}
                            </option>
                        ))}
                    </select>
                    {formik.touched.vehicle_status_id && formik.errors.vehicle_status_id ? (
                        <div className="text-red-500 text-sm">{formik.errors.vehicle_status_id}</div>
                    ) : null}
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="traction">
                        Tracción
                    </label>
                    <input
                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="traction"
                        name="traction"
                        value={formik.values.traction}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />

                    {formik.touched.traction && formik.errors.traction ? (
                        <div className="text-red-500 text-sm">{formik.errors.traction}</div>
                    ) : null}
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="fabrication_year">
                        Año de fabricación <span className="italic">(requerido)</span>
                    </label>
                    <select
                        className="px-3 py-2 w-full border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        id="fabrication_year"
                        name="fabrication_year"
                        value={formik.values.fabrication_year}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    >
                        <option value="">Selecciona el año</option>
                        {Array.from({ length: 50 }, (_, index) => {
                            const year = new Date().getFullYear() - index;
                            return (
                            <option key={year} value={year}>
                                {year}
                            </option>
                            );
                        })}
                    </select>

                    {formik.touched.fabrication_year && formik.errors.fabrication_year ? (
                        <div className="text-red-500 text-sm">{formik.errors.fabrication_year}</div>
                    ) : null}
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="description">
                        Descripción del vehículo
                    </label>
                    <textarea
                        aria-multiline
                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="description"
                        name="description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        rows={1}
                    />

                    {formik.touched.description && formik.errors.description ? (
                        <div className="text-red-500 text-sm">{formik.errors.description}</div>
                    ) : null}
                </div>
                
                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="acquisition_date">
                        Fecha estimada de adquisición <span className="italic">(requerido)</span>
                    </label>
                    <div className="w-full">
                        <ThemeProvider theme={theme}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es" localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                            <DatePicker
                                label="Fecha de adquisición"
                                disableFuture
                                value={formik.values.acquisition_date ? dayjs(formik.values.acquisition_date) : null}
                                onChange={handleAcquisitionDateChange}
                           />
                        </LocalizationProvider>
                        </ThemeProvider>
                    </div>
                    {formik.touched.acquisition_date && formik.errors.acquisition_date ? (
                        <div className="text-red-500 text-sm">{formik.errors.acquisition_date}</div>
                    ) : null}
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="fullname">
                        Fecha de la última mantención
                    </label>
                    <div className="w-full">
                        <ThemeProvider theme={theme}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es" localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                            <DatePicker
                                label="Fecha"
                                disableFuture
                                value={formik.values.last_maintenance_date ? dayjs(formik.values.last_maintenance_date) : null}
                                onChange={handleDateChange}
                           />
                        </LocalizationProvider>
                        </ThemeProvider>
                    </div>
                    {formik.touched.last_maintenance_date && formik.errors.last_maintenance_date ? (
                        <div className="text-red-500 text-sm">{formik.errors.last_maintenance_date}</div>
                    ) : null}
                </div>

                <div>
                    <label className="flex items-center justify-center px-4 py-4 bg-customBlue text-white text-sm font-bold rounded-lg cursor-pointer hover:bg-blue-900">
                        Agrega una imagen del vehículo
                        <input
                            type="file"
                            className="hidden"
                            accept="image/*"
                            name="vehicle_image"
                            onChange={(event) => {
                                if (event.currentTarget.files) {
                                    const file = event.currentTarget.files[0];
                                    formik.setFieldValue('vehicle_image', file);
                                }
                            }}
                            onBlur={formik.handleBlur}
                        />
                    </label>
                    { (formik.values.vehicle_image) ? (<div className="text-blue-500 text-sm"> Imagen subida correctamente. </div>) : null}
                    {formik.touched.vehicle_image && formik.errors.vehicle_image ? (
                            <div className="text-red-500 text-sm">{formik.errors.vehicle_image}</div>
                    ) : null}
                </div>

                <div className="mb-4">
                <label className="inline-flex items-center mt-3">
                    <input
                        type="checkbox"
                        className="form-checkbox h-5 w-5 text-gray-600"
                        checked={isCheckboxChecked}
                        onChange={handleCheckboxChange}
                    />
                    <span className="ml-2 text-gray-700 text-sm">
                        Confirmo que la información ingresada es verídica.
                    </span>
                </label>
            </div>

            </div>
        </div>
        <div className="flex justify-end">
            <button
                className={`font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${isCheckboxChecked ? 'bg-blue-500 hover:bg-blue-700' : 'bg-gray-500 hover:bg-gray-700 cursor-not-allowed'} text-white`}
                type="submit"
                disabled={!isCheckboxChecked}
            >
                {vehicleData ? <div> Modificar vehículo</div> : <div> Enviar </div>}
            </button>
        </div>
    </form>
  )
}

export default VehicleForm;