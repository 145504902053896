import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import LandingView from './views/landingPage';
import TravelAllowanceRequestFormView from './views/travelAllowanceRequests/travelAllowanceRequestFormView';
import VehiclesListView from './views/vehicles/vehiclesListView';
import ParticularVehicleView from './views/vehicles/particularVehicleView';
import { AuthProvider, msalInstance } from './utils/auth/authContext';
import AddVehicleFormView from './views/vehicles/addVehicleForm';
import ModifyVehicleFormView from './views/vehicles/modifyVehicleFormView';
import ExpenseReportRequestFormView from './views/expenseReportRequests/expenseReportRequestFormView';
import ExpenseSystemAdministratorView from './views/expenseReportRequests/administratorViews/expenseSystemAdministratorView';
import AllTravelAllowanceRequestsView from './views/travelAllowanceRequests/allTravelAllowanceRequestsView';
import AllExpenseReportRequestsView from './views/expenseReportRequests/allExpenseReportRequestsView';
import ShowMyTravelAllowanceRequestsView from './views/travelAllowanceRequests/showMyTravelAllowanceRequestsView';
import ShowMyExpenseReportRequestsView from './views/expenseReportRequests/showMyExpenseReportRequestsView';
import ParticularExpenseReportRequestView from './views/expenseReportRequests/particularExpenseReportRequestView';
import ParticularTravelAllowanceRequestView from './views/travelAllowanceRequests/particularTravelAllowanceRequestView';
import ModifyTravelAllowanceRequestFormView from './views/travelAllowanceRequests/modifyTravelAllowanceRequestFormView';
import VehiclesCalendarView from './views/vehicles/vehiclesCalendarView';
import VehicleUsagePerUserView from './views/vehicles/vehicleUsagePerUserView';
import TravelAllowanceStatisticsView from './views/travelAllowanceSystemTracking/statisticsView';
import ShowAllActivityRegistry from './views/travelAllowanceSystemTracking/allActivityRegistry';
import AlertReportsRepositoryView from './views/travelAllowanceSystemTracking/alertReportRepositoryView';
import BillAccountsAdministratorView from './views/expenseReportRequests/administratorViews/billAccountsAdministratorView';
import UnitsAdministratorView from './views/expenseReportRequests/administratorViews/unitsAdministratorView';
import PermissionsAdministratorView from './views/systemPermissionsControlPanel/permissionsAdministratorView';
import CategoriesAdministratorView from './views/expenseReportRequests/administratorViews/categoriesAdministratorView';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastProvider } from './context/useToast';
import { DataProvider } from './context/dataProvider';

function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <AuthProvider>
        <DataProvider>
        <ToastProvider>
        <ToastContainer position="top-center" autoClose={8000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover/>
        <Router>
          <Routes>
            <Route path="/" element={<LandingView/>}></Route>
            <Route path="/requestTravelAllowance" element={<TravelAllowanceRequestFormView/>}></Route>
            <Route path="/allTravelAllowanceRequests" element={<AllTravelAllowanceRequestsView/>}></Route>
            <Route path="/allExpenseReportRequests" element={<AllExpenseReportRequestsView/>}></Route>
            <Route path="/allMyTravelAllowanceRequests" element={<ShowMyTravelAllowanceRequestsView/>}></Route>
            <Route path="/allMyExpenseReportRequests" element={<ShowMyExpenseReportRequestsView/>}></Route>
            <Route path="/requestExpenseReport" element={<ExpenseReportRequestFormView/>}></Route>
            <Route path="/particularExpenseReportRequest/:id" element={<ParticularExpenseReportRequestView/>}></Route>
            <Route path="/particularTravelAllowanceRequest/:id" element={<ParticularTravelAllowanceRequestView/>}></Route>
            <Route path="/addVehicle" element={<AddVehicleFormView/>}></Route>
            <Route path="/editVehicle" element={<ModifyVehicleFormView/>}></Route>
            <Route path="/allVehicles" element={<VehiclesListView/>}></Route>
            <Route path="/vehicleDetails/:id" element={<ParticularVehicleView/>}></Route>
            <Route path="/editTravelAllowanceRequest" element={<ModifyTravelAllowanceRequestFormView/>}></Route>
            <Route path="/calendarOfVehicleUse" element={<VehiclesCalendarView/>}></Route>
            <Route path="/vehicleUsagePerUser" element={<VehicleUsagePerUserView/>}></Route>
            <Route path="/travelAllowanceStatistics" element={<TravelAllowanceStatisticsView/>}></Route>
            <Route path="/allActivityRegistry" element={<ShowAllActivityRegistry/>}></Route>
            <Route path="/alertReports" element={<AlertReportsRepositoryView/>}></Route>
            <Route path="/expenseReportSystemAdministration" element={<ExpenseSystemAdministratorView/>} />
            <Route path="/expenseBillAccountsAdministration" element={<BillAccountsAdministratorView/>} />
            <Route path="/expenseCategoriesAdministration" element={<CategoriesAdministratorView/>} />
            <Route path="/expenseUnitsAdministration" element={<UnitsAdministratorView/>} />
            <Route path="/ITPermissions-restricted" element={<PermissionsAdministratorView/>} />
          </Routes>
        </Router>
        </ToastProvider>
        </DataProvider>
      </AuthProvider>
    </MsalProvider>
  );
}

export default App;
