import { useAuthStatus } from '../../hooks/useAuthStatus';
import BlockScreen from '../blockScreen/blockScreen';
import { ShowAllTravelAllowanceRequests } from './showAllTravelAllowanceRequests';

export default function ShowMyTravelAllowanceRequests () {
    const isAuthenticated = useAuthStatus();

    if (!isAuthenticated) {
        return <BlockScreen/>
    }

    return (
      <div>
          <h2 className="py-3 text-gray-700 text-lg font-semibold">Solicitudes de viáticos </h2> 
          <ShowAllTravelAllowanceRequests {...{endpointURL: '/v1/travelAllowanceRequestController/allMyTravelAllowanceRequests'}}/>
        </div>
  )
}
