import React, { useState, useEffect, ChangeEvent  } from 'react';
import { useAuthStatus } from '../../../hooks/useAuthStatus';
import { useDataForEndpoint } from '../../../context/dataProvider';
import Loader from '../../../elements/loader';
import 'react-datepicker/dist/react-datepicker.css';
import Layout from '../../../components/layOutWrapper/layOutWrapper';
import VerticalLogo from "./../../../assets/images/VerticalLogo.png"
import ApproversAdministratorPanel from './unitPanels/approversAdministratorView';
import SubmittersAdministratorPanel from './unitPanels/submittersAdministratorView';
import BlockScreen from '../../../components/blockScreen/blockScreen';

interface OrganizationalUnit {
  organizational_unit_id: string;
  organizational_unit_name: string;
}

interface TopBarProps {
  onSelectView: (view: string) => void;
  selectedView: string;
}

interface LeftDrawerProps {
  units: OrganizationalUnit[];
  filter: string;
  onFilterChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSelectUnit: (unit: OrganizationalUnit) => void;
  selectedUnitId?: string;
}

interface MainContentProps {
  selectedView: string;
  unitId: string;
  unitName: string;
}

export default function UnitsAdministratorView () {
    return (
        <Layout 
            children={<UnitsAdministratorPanel/>} 
            sectionTitle="Administra las unidades de rendición"
        />
    )
}

function UnitsAdministratorPanel() {
  const [selectedView, setSelectedView] = useState<string>('Rendidores');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedUnit, setSelectedUnit] = useState<OrganizationalUnit | null>(null);
  const isAuthenticated = useAuthStatus();
  const [isLoading, setIsLoading] = useState(false);
  const organizationalUnits = useDataForEndpoint<OrganizationalUnit[]>("organizational_units");
  const [filteredUnits, setFilteredUnits] = useState<OrganizationalUnit[]>(organizationalUnits);

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    setSearchTerm(value);
    if (organizationalUnits) {
        setFilteredUnits(organizationalUnits.filter((unit: OrganizationalUnit) =>
          unit.organizational_unit_name.toLowerCase().includes(value.toLowerCase())
        ));
    }
  };

  useEffect(() => {
    if (organizationalUnits) {
        setFilteredUnits(organizationalUnits.filter(unit =>
            unit.organizational_unit_name.toLowerCase().includes(searchTerm.toLowerCase())
        ));
    }
  }, [organizationalUnits, searchTerm]);

  useEffect(() => {
    const allData = [
      organizationalUnits,
    ];
    
    if (isAuthenticated) {
        const isAnyDataUndefined = allData.some(data => data === undefined);
        setIsLoading(isAnyDataUndefined);
    } // Set loading to false if all data is loaded
  }, [organizationalUnits, isAuthenticated]);

  if (isLoading) {
    return <Loader/>
  } else if (!isAuthenticated) {
    return <BlockScreen/>
  }

  return (
    <div className="flex h-screen">
      <LeftDrawer 
        units={filteredUnits}
        filter={searchTerm}
        onFilterChange={handleFilterChange}
        onSelectUnit={(unit: OrganizationalUnit) => setSelectedUnit(unit)}
        selectedUnitId={selectedUnit?.organizational_unit_id}
      />
      <div className="flex-1 flex flex-col">
        <TopBar onSelectView={setSelectedView} selectedView={selectedView}/>
        <div className="flex-1 overflow-auto mt-5 ml-3">
          <MainContent selectedView={selectedView} unitId={selectedUnit?.organizational_unit_id ?? ''} unitName={selectedUnit?.organizational_unit_name ?? ''}/>
        </div>
      </div>
    </div>
  );
};

const LeftDrawer: React.FC<LeftDrawerProps> = ({ units, filter, onFilterChange, onSelectUnit, selectedUnitId }) => {
  if (!units) {
    return <Loader message={"Cargando unidades..."}/>;
  }

  return(
    <div className="w-64 h-full bg-gray-100 p-4 flex flex-col overflow-y-auto">
      <h3 className="mb-5 font-bold text-center">Selecciona una unidad</h3>
      <input 
        type="text"
        placeholder="Buscar unidad..."
        value={filter}
        onChange={onFilterChange}
        className="mb-4 p-2 border-2 border-gray-300"
      />
      {units.map(unit => (
        <button
          key={unit.organizational_unit_id}
          onClick={() => onSelectUnit(unit)}
          className={`mb-2 p-2 border-2 border-gray-300 text-left ${
            unit.organizational_unit_id === selectedUnitId ? 'bg-blue-500 text-white font-bold' : 'bg-white'
          }`}
        >
          {unit.organizational_unit_name}
        </button>
      ))}
    </div>
  )
};

const MainContent: React.FC<MainContentProps> = ({ selectedView, unitId, unitName }) => {
  if (unitId === '') {
    return (
      <div className="flex flex-col justify-center w-full h-screen">
        <div className="text-center mb-5 font-bold text-md items-center">
          Selecciona una unidad para comenzar.
        </div>
        <div className="flex justify-center items-center">
          <img src={VerticalLogo} alt="Logo vertical de Dictuc S.A." className="object-scale-down sm:h-24 md:h-60 xl:h-75 sm:w-24 md:w-60 xl:w-75" />
        </div>
      </div>
    )
  }
  switch (selectedView) {
    case 'Rendidores':
      return <SubmittersAdministratorPanel selectedUnitId={unitId} selectedUnitName={unitName}/>;
    case 'Aprobadores':
      return <ApproversAdministratorPanel selectedUnitId={unitId} selectedUnitName={unitName}/>;
    default:
      return null;
  }
};

const TopBar: React.FC<TopBarProps> = ({ onSelectView, selectedView }) => (
  <div className="flex justify-evenly bg-blue-500 p-4 text-white font-semibold items-center">
    <div 
      onClick={() => onSelectView('Rendidores')} 
      className={`cursor-pointer hover:bg-blue-700 px-2 py-1 ${selectedView === 'Rendidores' ? 'bg-blue-700 font-bold rounded-md px-2 py-1' : ''}`}
    >
      Rendidores
    </div>
    <div 
      onClick={() => onSelectView('Aprobadores')} 
      className={`cursor-pointer hover:bg-blue-700 px-2 py-1 ${selectedView === 'Aprobadores' ? 'bg-blue-700 font-bold rounded-md px-2 py-1' : ''}`}
    >
      Aprobadores
    </div>
  </div>
);
