import { useState } from "react";
import DownloadIcon from '@mui/icons-material/Download';
import performApiRequest from "../utils/performApiRequest";
import { API_ENDPOINTS } from "../utils/endpoints";
import { useToast } from "../context/useToast";

interface DownloadButtonProps {
	fileName: string;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({ fileName }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { showSuccessToast, showErrorToast } = useToast();

  const handleDownload = async () => {
    setIsLoading(true);
	const requestOptions = {
		method: 'GET',
		responseType: 'blob'
	}

    try {
    	const response = await performApiRequest(
			`${API_ENDPOINTS.downloadJustificationFileFromTravelAllowanceRequest}/${fileName}`, 
			requestOptions
		);

		const blob = await response.data;
		const downloadUrl = window.URL.createObjectURL(blob);

		const link = document.createElement('a');
		link.href = downloadUrl;
		link.setAttribute('download', fileName); // or another filename obtained from the response headers
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		window.URL.revokeObjectURL(downloadUrl);
		showSuccessToast("El archivo fue descargado correctamente.")
	} catch (error) {
		console.error('Download error:', error);
		showErrorToast("Hubo un error en la descarga del archivo")
	} finally {
		setIsLoading(false);
	}};

  	return (
    	<button onClick={handleDownload} disabled={isLoading} className="text-sm">
      		<DownloadIcon/> {isLoading ? ' Descargando...' : ''}
    	</button>
  	);
};

export default DownloadButton;