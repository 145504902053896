import React, { useState } from 'react';
import performApiRequest from '../utils/performApiRequest';
import { API_ENDPOINTS } from '../utils/endpoints';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DownloadButton from './downloadButton';

interface FileModalProps {
    isOpen: boolean;
    onClose: () => void;
    uploadedFiles: {[key: string]: any};
    openFileUploadModalInParent: () => void;
}

const ExpenseReportRequestFilesModal: React.FC<FileModalProps> = ({ isOpen, onClose, uploadedFiles, openFileUploadModalInParent }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const file = uploadedFiles[activeIndex];
    const goToNextFile = () => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % uploadedFiles.length);
    };
    
    const goToPrevFile = () => {
        setActiveIndex((prevIndex) => (prevIndex - 1 + uploadedFiles.length) % uploadedFiles.length);
    };

    const deleteFile = async (file_id: number) => {
        if (uploadedFiles.length <= 1) {
            alert("Debe haber al menos un archivo por gasto.");
            return;
        }

        if (window.confirm("Presiona Aceptar para confirmar la eliminación del archivo.")) {
            const endpoint = API_ENDPOINTS.deleteFileFromExpenseReportRequest;
            
            const requestOptions = {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({file_id})
            }

            const response = await performApiRequest(endpoint, requestOptions);
            if (response.status === 204) {
                window.location.reload();
            }
        }
    }


    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-content mt-10">
                <div className="flex flex-col md:flex-row md:justify-between">
                    <button onClick={onClose} className="w-full bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mb-5">
                        Cerrar
                    </button>
                </div>
                <h2 className="text-gray-700 text-lg font-bold mb-4 text-center">Archivos subidos</h2>
                { uploadedFiles.length > 1 && (
                    <div className="text-center text-md"> Utiliza las flechas para desplazarte entre los archivos. </div>
                )}
                <div className="flex flex-col p-4 bg-white shadow rounded-lg">
                    <div className="relative flex items-center w-full">
                        {uploadedFiles.length > 1 && (
                            <button onClick={goToPrevFile}>{<KeyboardArrowLeftIcon fontSize="large"/>}</button>
                        )}
                        {(uploadedFiles.length > 0) ? (
                        <div className="flex flex-col lg:flex-row -mx-4 mb-4 w-full">
                            <div className="flex flex-col w-full lg:w-1/3 px-4 mb-4 lg:mb-0">
                                <div className="w-full lg:w-1/4 px-4 flex justify-center items-start">
                                    <div>
                                        <h2 className="text-gray-700 text-lg text-center font-bold mb-4">Archivo {file.id}</h2>
                                    </div>
                                    <DownloadButton fileName={file.file_name}/>
                                </div>
                                <div className="mt-10 w-full lg:w-3/4 px-4 flex flex-col justify-between">
                                    <div className="grid grid-cols-1 md:grid-cols-2">
                                        <button className="mr-2 mt-10 bg-red-600 hover:bg-red-800 text-white px-2 py-1 rounded-md" onClick={() => deleteFile(file.id)}>
                                            Eliminar este documento 
                                        </button>
                                        <button onClick={openFileUploadModalInParent} className="mr-2 mt-10 bg-blue-500 hover:bg-blue-700 text-white px-2 py-1 rounded-md">
                                            Agregar un archivo
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col w-full lg:w-2/3 px-4 mb-4 lg:mb-0">
                                <div className="w-full h-full flex justify-center items-start -mx-10">
                                    <div className="w-full h-full mb-4 lg:mb-0">
                                        {(isPDF(file.file_name)) ? (
                                            <embed src={file.file_url} type="application/pdf" className="w-full h-full -mx-10"/>
                                        ) : (
                                            <img src={file.file_url} alt={`Archivo ${file.id}`} className="max-w-full h-auto"/>
                                        )}
                                    </div>
                                </div>
                            </div>            
                        </div>
                        ) : (
                            <div className="text-center font-bold"> No ha subido archivos. </div>
                        )}
                        {uploadedFiles.length > 1 && (
                            <button onClick={goToNextFile}>{<KeyboardArrowRightIcon fontSize="large"/>}</button>
                        )}
                    </div>
                    </div>
                </div>
        </div>
    );
};

const isPDF = (fileName: string) => {
    return /\.pdf$/i.test(fileName);
};

export default ExpenseReportRequestFilesModal;