import React, { useState, useEffect } from 'react'
import 'dayjs/locale/es';
import { useAuthStatus } from '../../hooks/useAuthStatus';
import BlockScreen from '../../components/blockScreen/blockScreen';
import { useNavigate } from 'react-router-dom';
import Loader from '../../elements/loader';
import performApiRequest from '../../utils/performApiRequest';
import ReusableTable from '../../elements/reusableTable';
import { TableCell, TableRow } from '@mui/material';
import { VisibleTables } from '../../types/fields';
import Layout from '../../components/layOutWrapper/layOutWrapper';
import showExactDate from '../../utils/showExactDate';

export default function VehicleUsagePerUserView () {
  return (
      <Layout sectionTitle="Uso de vehículos por usuario">
          <VehicleUsagePerUser/>
      </Layout>
  )
}

const VehicleUsagePerUser: React.FC = () => {
    const isAuthenticated = useAuthStatus();
    const [usageList, setUsageList] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [visibleTables, setVisibleTables] = useState<VisibleTables>({});
    
    const toggleTableVisibility = (index: number) => {
      setVisibleTables(prev => ({ ...prev, [index]: !prev[index] }));
    };
    
    useEffect(() => {
        if (isAuthenticated) {
            const fetchUsageList = async () => {
                try {
                    const endpoint = `/v1/vehicularResourcesMonitor/getVehicleUsagePerUserList`;
                    const requestOptions = {
                      method: 'GET'
                    }
                    const response = await performApiRequest(endpoint, requestOptions);
                    setUsageList(response.data);
                    setIsLoading(false);
                    // console.log(response.data);
                } catch (error) {
                    console.error('There was an error fetching the drivers list:', error);
                }
            };

            if (isAuthenticated) {
                fetchUsageList();
            }
        }
    }, [isAuthenticated]);

    const handleClick = (vehicle_id: number) => {
        navigate(`/vehicleDetails/${vehicle_id}`);
    }

    if (!isAuthenticated) {
        return <BlockScreen/>
    }

    if (isLoading) {
        return <Loader/>
    }

    return (
        <div className="flex flex-column justify-center items-center p-4">
          <div className="overflow-x-auto rounded">
          <h2 className="py-3 text-gray-700 text-lg font-semibold mb-5 text-center">Uso de vehículos relacionado con viáticos</h2> 
          <h3 className="py-3 text-gray-700 text-lg font-semibold mb-10 text-center">Por usuario que requiere el viático: </h3> 
 
          {usageList.map((user: any, index: number) => (
            <ReusableTable
              key={index}
              title={`${user.requests[index].requestingUserData.names} ${user.requests[index].requestingUserData.ap_pat} ${user.requests[index].requestingUserData.ap_mat}`}
              data={user.requests}
              isVisible={visibleTables[1]}
              toggleVisibility={() => toggleTableVisibility(1)}
              headers={[
                { key: 'id', label: '' },
                { key: 'vehicle_plate', label: 'Patente del vehículo a ocupar' },
                { key: 'start_date', label: 'Fecha de inicio del viaje' },
                { key: 'end_date', label: 'Fecha de término del viaje' },
                { key: 'request_status', label: 'Estado de la solicitud' },
              ]}
              renderRow={(request, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    <button onClick={() => handleClick(request.vehicle.id)}>
                      {request.vehicle.vehicle_plate}
                    </button>
                  </TableCell>
                  <TableCell align="center">{showExactDate(request.travel_start_date)}</TableCell>
                  <TableCell align="center">{showExactDate(request.travel_end_date)}</TableCell>
                  <TableCell align="center">{request.high_order_status}</TableCell>
                </TableRow>
              )}
            />
          ))}
          {(usageList.length === 0) &&
            (<div className="text-center text-md"> Aún no hay información sobre el uso de vehículos en los viáticos.</div>)
          }
        </div>
      </div>
  )
}
