import React, { useState, useEffect } from 'react';
import performApiRequest from '../../../../utils/performApiRequest';
import { useAuthStatus } from '../../../../hooks/useAuthStatus';
import { useDataForEndpoint } from '../../../../context/dataProvider';
import Loader from '../../../../elements/loader';
import { OrganizationalUnit } from '../../../../types/fields';
import 'react-datepicker/dist/react-datepicker.css';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { API_ENDPOINTS } from '../../../../utils/endpoints';
import { useToast } from '../../../../context/useToast';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

interface User {
  id: string;
  names: string;
  ap_pat: string;
  ap_mat: string;
  rut: string;
  dv_rut: string;
  startDate: Date;
  endDate: Date;
  position: number;
}

interface AdministratorPanelProps {
  selectedUnitId?: string;
  selectedUnitName?: string;
}

export default function SubmittersAdministratorPanel({ selectedUnitId, selectedUnitName }: AdministratorPanelProps) {
  const isAuthenticated = useAuthStatus();
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const organizationalUnits = useDataForEndpoint<OrganizationalUnit[]>('organizational_units');
  const [availableUsers, setAvailableUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { showSuccessToast, showErrorToast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        const requestOptions = {
          method: 'GET',
        };
        const response = await performApiRequest(`/v1/users/getUsersInfoAll`, requestOptions);
        setAvailableUsers(response.data.usersData);
      } catch (error) {
        console.error('Error fetching vehicle availability', error);
      }
    };
    fetchUsersData();
  }, [isAuthenticated]);

  useEffect(() => {
    const allData = [
      organizationalUnits,
    ];
    
    if (isAuthenticated) {
        const isAnyDataUndefined = allData.some(data => data === undefined);
        setIsLoading(isAnyDataUndefined);
    } // Set loading to false if all data is loaded
  }, [organizationalUnits, isAuthenticated]);

  useEffect(() => {
    if (selectedUnitId) {
      fetchSubmittersByUnitData();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnitId]);

  const userOptions = availableUsers.map(user => ({
    value: user.id,
    label: `${user.names} ${user.ap_pat}. RUT: ${user.rut}-${user.dv_rut}`,
  }));
  
  const fetchSubmittersByUnitData = async () => {
    setIsLoading(true);
    const requestOptions = {
      method: 'GET'
    };
    try {
      const response = await performApiRequest(
        `${API_ENDPOINTS.getExpenseSubmitterFromOrganizationalUnit}/${selectedUnitId}`,
        requestOptions
      );
      if (response.status === 200) {
        const formattedUsers = response.data.submitters.map((user: User) => ({
            ...user,
            startDate: new Date(user.startDate),
            endDate: new Date(user.endDate)
        }))
        setSelectedUsers(formattedUsers);
      } else if (response.status === 204) {
        setSelectedUsers([]);
      }
    } catch (error) {
      console.error('Failed to fetch approvers:', error);
    }
    setIsLoading(false);
  };

    const handleAddUser = (userId: string) => {
        const userToAdd = availableUsers.find(user => Number(user.id) === Number(userId));
        if (userToAdd && !selectedUsers.some(u => Number(u.id) === Number(userId))) {
            // Initialize startDate and endDate when adding a new user
            const newUser = {
                ...userToAdd,
                startDate: new Date(), // Set default startDate
                endDate: new Date()   // Set default endDate
            };
            setSelectedUsers([...selectedUsers, newUser]);
        }
        setAvailableUsers(prevUsers => prevUsers.filter(user => Number(user.id) !== Number(userId)));
    };

  const handleDeleteUser = (userId: string) => {
    setSelectedUsers(selectedUsers.filter(user => user.id !== userId));
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>, dateType: 'startDate' | 'endDate', userId: string) => {
    const dateValue = event.target.value; // Already in 'YYYY-MM-DD' format

    if (!dateValue) {
        console.error("Selected date is invalid.");
        return;
    }

    const newDate = new Date(dateValue);
    if (isNaN(newDate.getTime())) {
        console.error("Invalid date format.");
        return;
    }

    setSelectedUsers(prevUsers => prevUsers.map(user => {
        if (user.id === userId) {
            const updatedUser = { ...user, [dateType]: newDate };
            return updatedUser;
        }
        return user;
        }));
    };

  const handleSubmit = async () => {
    if (!selectedUnitId) {
      alert("Debes seleccionar una unidad.");
      return;
    }
  
    if (selectedUsers.length === 0) {
        alert("Debes seleccionar al menos a un rendidor.")
    }
    // Ensure each step has at least one user and all users have valid dates
    for (const user of selectedUsers) {
        if (!user.startDate || !user.endDate) {
          alert("Cada rendidor debe tener una fecha de inicio y de término para rendir.");
          return;
        }
    }
  
    const payload = {
      unitId: selectedUnitId,
      selectedUsers
    };
  
    try {
        const requestOptions = {
            method: 'POST',
            data: payload
        }
        const response = await performApiRequest(API_ENDPOINTS.addExpenseSubmitterToOrganizationalUnit, requestOptions);
        if (response.status === 201) {
            showSuccessToast('Los datos se actualizaron con éxito.')
        }
        } catch (error: any) {
            showErrorToast(`Error: ${error.message}`);
        }
    };

    if (isLoading) {
        return <Loader/>
    }

    return (
    <div className="flex flex-col items-center justify-center">
        <div className="flex flex-col justify-center items-center mb-4">
            <h2 className="font-bold mb-2 text-lg"> Unidad de rendición: {selectedUnitName} </h2>
        </div>
        <div className="flex flex-col justify-center items-center mb-4">
            <h3 className="font-semibold mb-2"> Selecciona usuarios para asignarles el rol de rendidor. </h3>
            <Select
              options={userOptions}
              onChange={(selectedOption: any) => handleAddUser(selectedOption.value)}
              placeholder="Selecciona o busca un usuario..."
              isClearable
              isSearchable
              className="min-w-64"
            />
        </div>
        {selectedUsers.map((user, index) => (
            <div key={index} className="w-full grid grid-cols-1 md:grid-cols-4 gap-4 my-2 justify-evenly items-center flex flex-row bg-beige py-2 px-4 user">
                <div>
                    {user.names} {user.ap_pat} {user.ap_mat}. {'\n'} RUT: {user.rut}-{user.dv_rut}
                </div>
                <div>
                    <label className="justify-center"> Desde: </label> 
                    <input
                        type="date"
                        value={user.startDate.toISOString().substring(0, 10)}  
                        onChange={(event) => handleDateChange(event, 'startDate', user.id)}
                    />
                </div>
                <div>
                    <label className="justify-center"> Hasta: </label>
                        <input
                            type="date"
                            value={user.endDate.toISOString().substring(0, 10)}  
                            onChange={(event) => handleDateChange(event, 'endDate', user.id)}
                        />
                </div>
                <div> 
                    <button
                        className="ml-5"
                        onClick={() => handleDeleteUser(user.id)}>
                        <PersonRemoveIcon/>
                    </button>
                </div>
            </div>
        ))}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <button
                onClick={() => navigate('/expenseReportSystemAdministration')}
                className={`submit-button py-2 px-5 rounded-md bg-red-600 hover:bg-red-800 text-white mt-5`}>
                Volver
            </button>
            <button
                onClick={handleSubmit}
                disabled={selectedUsers.length < 1}
                className={`submit-button py-2 px-5 rounded-md bg-blue-400 hover:bg-blue-600 text-white mt-5 ${selectedUsers.length < 1 ? 'bg-grey-800':'bg-blue-400'}`}>
                Guardar
            </button>
        </div>
    </div>
  );
}
