import Layout from "../../components/layOutWrapper/layOutWrapper";
import { useState, useEffect } from 'react';
import performApiRequest from "../../utils/performApiRequest";
import 'react-calendar/dist/Calendar.css';
import { API_ENDPOINTS } from "../../utils/endpoints";
import { esES } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/es';
import { format } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'react-calendar/dist/Calendar.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';
import dayjs, { Dayjs } from 'dayjs';
import theme from "../../utils/calendarTheme";
import ReusableTable from "../../elements/reusableTable";
import { TableCell, TableRow } from '@mui/material';
import Loader from "../../elements/loader";
import { DownloadExcelButton } from "../../elements/downloadExcelButton";

interface StatisticsData {
    usersWithMostRequests: {[key: string]: any},
    usersWithMostApprovedRequests: {[key: string]: any},
    usersWithHighestAmountsApproved: {[key: string]: any},
    benefitCentersWithMostRequests: {[key: string]: any},
    organizationalUnitsWithMostRequests: {[key: string]: any}
}

interface VisibleTables {
  [key: string]: boolean;
}

export default function TravelAllowanceStatisticsView () {
    return (
        <Layout sectionTitle="Estadísticas sobre viáticos">
            <TravelAllowanceStatisticsPanel/>
        </Layout>
    )
}

const TravelAllowanceStatisticsPanel: React.FC = () => {
    const [statisticsData, setStatisticsData] = useState<StatisticsData>({usersWithMostApprovedRequests: {}, usersWithHighestAmountsApproved: {}, usersWithMostRequests: {}, benefitCentersWithMostRequests: {}, organizationalUnitsWithMostRequests: {} });
    const [selectedStartDate, setSelectedStartDate] = useState<Date>(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());
    const [isLoading, setIsLoading] = useState(true);
    const [visibleTables, setVisibleTables] = useState<VisibleTables>({});
    
    const toggleTableVisibility = (index: number) => {
      setVisibleTables(prev => ({ ...prev, [index]: !prev[index] }));
    };
  
    useEffect(() => {
      const fetchStatistics = async () => {
        try {
          const startDate = format(selectedStartDate, 'yyyy-MM-dd');
          const endDate = format(selectedEndDate, 'yyyy-MM-dd');
          const requestOptions = {
            method: 'POST',
            data: { startDate, endDate }
          };
          const response = await performApiRequest(API_ENDPOINTS.obtainTravelAllowancesMainStatistics, requestOptions);
          setStatisticsData(response.data);
          setIsLoading(false);
          console.log('response statistics: ',response.data);
        } catch (error) {
          console.error('Error fetching vehicle availability', error);
        }
      };
      if (selectedStartDate <= selectedEndDate) {
        fetchStatistics();
      }
    }, [selectedStartDate, selectedEndDate]);
  
    const handleStartDateChange = (date: Dayjs | null) => {
        if (date) {
          const newDate = date.toDate();
          setSelectedStartDate(newDate);
        }
    };
      
    const handleEndDateChange = (date: Dayjs | null) => {
        if (date) {
          const newDate = date.toDate();
          setSelectedEndDate(newDate);
        }
    };

    if (isLoading) {
        return <Loader/>
    }

    return (
      <div>
        <div className="flex flex-col md:flex-row justify-between">
          <div className="flex flex-col">
            <h1 className="text-xl font-semibold mb-5">Estadísticas sobre los viáticos</h1>
          </div>
        </div>

        <div className="flex flex-col">
            <h1 className="text-lg font-semibold mb-5">Selecciona un rango de fechas para ver las estadísticas del periodo.</h1>
            <div className="flex flex-col md:flex-row justify-left gap-5">
              <div>
                  <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es" localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                      <DatePicker
                        label="Desde"
                        value={selectedStartDate ? dayjs(selectedStartDate) : null}
                        onChange={handleStartDateChange}
                        className="w-full"
                      />
                    </LocalizationProvider>
                  </ThemeProvider>
              </div>
              <div>
                  <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es" localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                      <DatePicker
                        label="Hasta"
                        value={selectedEndDate ? dayjs(selectedEndDate) : null}
                        onChange={handleEndDateChange}
                        className="w-full"
                      />
                    </LocalizationProvider>
                  </ThemeProvider>
              </div>
            </div>
          </div>
        <div className="grid md:grid-cols-2 gap-4 mt-10">
          <div>
            <ReusableTable
              title="Funcionarios con más solicitudes de viáticos"
              data={statisticsData.usersWithMostRequests}
              isVisible={visibleTables[1]}
              toggleVisibility={() => toggleTableVisibility(1)}
              headers={[
                { key: 'id', label: '' },
                { key: 'employee_name', label: 'Funcionario' },
                { key: 'total_requests_count', label: 'Total de solicitudes' },
                { key: 'in_process_requests_count', label: 'Solicitudes en proceso' },
                { key: 'paid_requests_count', label: 'Solicitudes pagadas' },
                { key: 'rejected_requests_count', label: 'Solicitudes rechazadas' }
              ]}
              renderRow={(row, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{`${row.userData["names"]} ${row.userData["ap_pat"]} ${row.userData["ap_mat"]}`}</TableCell>
                  <TableCell align="center">{row['total_requests']}</TableCell>
                  <TableCell align="center">{Number(row['total_requests']) - (Number(row['Cantidad de solicitudes pagadas']) - Number(row['Cantidad de solicitudes rechazadas']))}</TableCell>
                  <TableCell align="center">{row['Cantidad de solicitudes pagadas']}</TableCell>
                  <TableCell align="center">{row['Cantidad de solicitudes rechazadas']}</TableCell>
                </TableRow>
              )}
            />
          </div>

          <div>
            <ReusableTable
              title="Funcionarios con mayor cantidad de viáticos aprobados"
              data={statisticsData.usersWithMostApprovedRequests}
              isVisible={visibleTables[2]}
              toggleVisibility={() => toggleTableVisibility(2)}
              headers={[
                { key: 'id', label: '' },
                { key: 'employee_name', label: 'Funcionario' },
                { key: 'paid_requests_count', label: 'Solicitudes pagadas' }
              ]}
              renderRow={(row, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{`${row.userData["names"]} ${row.userData["ap_pat"]} ${row.userData["ap_mat"]}`}</TableCell>
                  <TableCell align="center">{row['total_requests']}</TableCell>
                </TableRow>
              )}
            />
          </div>

          <div>
            <ReusableTable
              title="Centros de beneficios con más solicitudes"
              data={statisticsData.benefitCentersWithMostRequests}
              isVisible={visibleTables[3]}
              toggleVisibility={() => toggleTableVisibility(3)}
              headers={[
                { key: 'id', label: '' },
                { key: 'employee_name', label: 'Centro de beneficio' },
                { key: 'total_requests_count', label: 'Total de solicitudes' },
                { key: 'in_process_requests_count', label: 'Solicitudes en proceso' },
                { key: 'paid_requests_count', label: 'Solicitudes pagadas' },
                { key: 'rejected_requests_count', label: 'Solicitudes rechazadas' }
              ]}
              renderRow={(row, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{`${row.benefitCenterData.benefit_center_name}`}</TableCell>
                  <TableCell align="center">{row['total_requests']}</TableCell>
                  <TableCell align="center">{Number(row['total_requests']) - (Number(row['Cantidad de solicitudes pagadas']) - Number(row['Cantidad de solicitudes rechazadas']))}</TableCell>
                  <TableCell align="center">{row['Cantidad de solicitudes pagadas']}</TableCell>
                  <TableCell align="center">{row['Cantidad de solicitudes rechazadas']}</TableCell>
                </TableRow>
              )}
            />
          </div>

          <div>
            <ReusableTable
              title="Funcionarios con mayores montos aprobados"
              data={statisticsData.usersWithHighestAmountsApproved}
              isVisible={visibleTables[4]}
              toggleVisibility={() => toggleTableVisibility(4)}
              headers={[
                { key: 'id', label: '' },
                { key: 'employe_name', label: 'Funcionario' },
                { key: 'total_clp_value', label: 'Monto total aprobado' },
              ]}
              renderRow={(row, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{`${row.userData["names"]} ${row.userData["ap_pat"]} ${row.userData["ap_mat"]}`}</TableCell>
                  <TableCell align="center">{row['total_clp_value']} CLP</TableCell>
                </TableRow>
              )}
            />
          </div>

          <div>
            <ReusableTable
              title="Unidades organizacionales con más solicitudes"
              data={statisticsData.organizationalUnitsWithMostRequests}
              isVisible={visibleTables[5]}
              toggleVisibility={() => toggleTableVisibility(5)}
              headers={[
                { key: 'id', label: '' },
                { key: 'organizational_unit_name', label: 'Unidad organizacional' },
                { key: 'total_requests_count', label: 'Total de solicitudes' },
                { key: 'in_process_requests_count', label: 'Solicitudes en proceso' },
                { key: 'paid_requests_count', label: 'Solicitudes pagadas' },
                { key: 'rejected_requests_count', label: 'Solicitudes rechazadas' }
              ]}
              renderRow={(row, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{`${row.organizationalUnitData.organizational_unit_name}`}</TableCell>
                  <TableCell align="center">{row['total_requests']}</TableCell>
                  <TableCell align="center">{Number(row['total_requests']) - (Number(row['Cantidad de solicitudes pagadas']) - Number(row['Cantidad de solicitudes rechazadas']))}</TableCell>
                  <TableCell align="center">{row['Cantidad de solicitudes pagadas']}</TableCell>
                  <TableCell align="center">{row['Cantidad de solicitudes rechazadas']}</TableCell>
                </TableRow>
              )}
            />
          </div>
          <div>
          </div>

          
        </div>
        <div className="grid md:grid-cols-2 gap-4 mt-10">
          <div>
            <DownloadExcelButton 
                start_date={format(selectedStartDate, 'yyyy-MM-dd')} 
                end_date={format(selectedEndDate, 'yyyy-MM-dd')}
                entity={"travelAllowanceRequest"}
            />
          </div>

          <div>
            <DownloadExcelButton 
                start_date={format(selectedStartDate, 'yyyy-MM-dd')} 
                end_date={format(selectedEndDate, 'yyyy-MM-dd')}
                entity={"expenseReportRequest"}
            />
          </div>

          <div>
            <DownloadExcelButton 
                start_date={format(selectedStartDate, 'yyyy-MM-dd')} 
                end_date={format(selectedEndDate, 'yyyy-MM-dd')}
                entity={"obtainDataTransfer"}
            />
          </div>

          <div>
            <DownloadExcelButton 
                start_date={format(selectedStartDate, 'yyyy-MM-dd')} 
                end_date={format(selectedEndDate, 'yyyy-MM-dd')}
                entity={"obtainExpenseDataTransfer"}
            />
          </div>
        </div>
      </div>
    );
  };