import { TextEditorMenuBar } from "./textEditorMenuBar";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import Highlight from "@tiptap/extension-highlight";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import Image from "@tiptap/extension-image";

// Code strongly inspired by https://github.com/mahmoud-bebars/Tiptap_Editor_Component/blob/main/src/App.jsx

interface TextEditorProps {
  content: string;
  setContent: (content: string) => void;
  handleSubmit: () => void;
}

export const TextEditor: React.FC<TextEditorProps> = ({ content, setContent, handleSubmit }) => {
  const editor = useEditor({
    editorProps: {
      attributes: {
        class:
          "prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none",
      },
    },
    extensions: [
      TextStyle,
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
      }),
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      Highlight,
      Underline,

      Link.configure({
        openOnClick: true,
        validate: (href) => /^https?:\/\//.test(href),
        HTMLAttributes: {
          class: "link",
        },
      }),
      Image.configure({
        allowBase64: true,
        inline: true,
      })
    ],
    content: content,
    onUpdate({ editor }) {
      setContent(editor.getHTML());
    },
  });

  return (
    <div className="w-full lg:flex md:flex flex-col justify-left">
      {editor && <TextEditorMenuBar editor={editor} />}
      <EditorContent
        editor={editor}
        className="bg-white max-w-lg  border-2 border-black w-full p-2 rounded-md focus:border-none"
      />
      <button className="bg-blue-600 max-w-lg hover:bg-blue-800 text-white rounded-md px-2 mt-4 py-1 justify-end" onClick={handleSubmit}>Publicar</button>
    </div>
  );
};
