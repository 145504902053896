import React, { useState } from 'react';

interface ModalProps {
  title: string;
  message: string;
  isOpen: boolean;
  actionName: string;
  endpoint: string;
  isCommentMandatory: boolean;
  onClose: () => void;
  onSend: (inputValue: string) => void;
}

const GenericReviewModal: React.FC<ModalProps> = ({ title, message, isOpen, actionName, isCommentMandatory, onClose, onSend }) => {
  const [inputValue, setInputValue] = useState('');

  if (!isOpen) {
    return null;
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleSend = () => {
    if (isCommentMandatory && !inputValue.trim()) {
      alert('Debes agregar un comentario.');
      return;
    }
    onSend(inputValue);
    setInputValue('');
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          <div className="mx-auto flex items-center justify-between">
            <h3 className="text-lg leading-6 font-medium text-gray-900 text-center">{title}</h3>
            <button onClick={onClose} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center">
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </button>
          </div>
          <div className="mt-2 px-7 py-3">
          <p className="text-sm text-gray-500">{message}</p>
            {isCommentMandatory && <p className="text-sm text-red-500">* Agrega tus razones</p>}
            <input 
              type="text" 
              value={inputValue} 
              onChange={handleInputChange} 
              className="mt-3 w-full px-3 border-gray-300 rounded-md shadow-sm" 
              placeholder={isCommentMandatory ? "Agrega una justificación (obligatorio)..." : "Comentario (Opcional)"}
              required={isCommentMandatory}
            />
          </div>
          <div className="items-center px-4 py-3">
            <button onClick={handleSend} className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500">
              {actionName}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenericReviewModal;