import { API_ENDPOINTS } from "./endpoints";
import performApiRequest from "./performApiRequest";

export async function obtainExchangeRate(date: string, currencyCode: string): Promise<number> {
    const url = `${API_ENDPOINTS.obtainExchangeRate}?date=${encodeURIComponent(date)}&currencyCode=${currencyCode}`;
  
    try {
      const response = await performApiRequest(url);
      if (response.status !== 200) {
          throw new Error('Network response was not ok from backend');
      }
      const data = response.data;
      console.log('Response from backend:', data);

      if (data && data.rate) {
          return data.rate;
      } else {
          throw new Error('No rate found in the response');
      }
    } catch (error) {
        console.error('Error fetching exchange rate:', error);
        return 0;
    }
}