import React from 'react';
import DictucLogoForUpperBar from "./../assets/images/DictucForUpperBar.png"

interface SectionBarProps {
    title: string;
  }

const SectionBar: React.FC<SectionBarProps> = ({ title }) => {
    return (
        <div className="mb-10">
            <div className="bg-sectionBarBackgroundColor shadow flex items-center sm:mt-2 xl:mt-5 sm:h-10">
                <div className="flex items-center justify-between px-4">
                    <div className="flex items-center">
                        <img src={DictucLogoForUpperBar} alt="Dictuc logo" className="h-5 w-auto"/>
                        <div className="border-r border-sectionBarTitle mx-2 h-6"></div>
                        <span className="text-sectionBarTitle text-md font-bold">{title}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SectionBar;
