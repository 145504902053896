import React, { useState } from 'react';
import performApiRequest from '../utils/performApiRequest';
import { API_ENDPOINTS } from '../utils/endpoints';
import { useToast } from '../context/useToast';

interface DownloadExcelButtonProps {
	start_date: string,
	end_date: string,
  entity: string
}

export const DownloadExcelButton: React.FC<DownloadExcelButtonProps> = ({ start_date, end_date, entity }) => {
  const [isLoading, setIsLoading] = useState(false);
	const { showSuccessToast, showErrorToast } = useToast();

    const handleDownload = async () => {
      setIsLoading(true);
  
      try {
        const requestOptions = {
          method: 'POST',
          data: {
            startDate: start_date,
            endDate: end_date
          },
          responseType: 'blob'
        }

        let endpoint = '';
        if (entity === 'travelAllowanceRequest') {
          endpoint = API_ENDPOINTS.downloadExcelReportFromTravelAllowanceRequest;
        } else if (entity === 'expenseReportRequest') {
          endpoint = API_ENDPOINTS.downloadExcelReportFromExpenseReportRequest;
        } else if (entity === 'obtainDataTransfer') {
          endpoint = API_ENDPOINTS.downloadExcelDataTransfer;
        } else if (entity === 'obtainExpenseDataTransfer') {
          endpoint = API_ENDPOINTS.downloadExcelExpenseDataTransfer;
        }
        
        const response = await performApiRequest(
          endpoint,
          requestOptions
        )
  
        const blob = await response.data;
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
  
        const filename = response.headers.get('Content-Disposition')?.split('filename=')[1] || 'report.xlsx';
        a.setAttribute('download', filename);
        document.body.appendChild(a);
        a.click();
  
        window.URL.revokeObjectURL(downloadUrl);
        a.remove();
		    showSuccessToast('Archivo descargado exitosamente.')
      } catch (error) {
        console.error('Download failed:', error);
        showErrorToast('Hubo un error en la descarga del archivo.')
      } finally {
        setIsLoading(false);
      }
    };
  
    return (
      <button onClick={handleDownload} disabled={isLoading} className="bg-blue-600 text-white py-1 px-2 w-6/12 justify-center">
        {isLoading ? 'Generando reporte y descargando...' : `Descargar reporte sobre ${entity === 'travelAllowanceRequest' ? ' solicitudes de viático.' : entity === 'expenseReportRequest' ? ' rendiciones de gastos.' : entity === 'obtainDataTransfer' ? ' data transfer viáticos.' : ' data transfer gastos.'}`}
      </button>
    );
}
  