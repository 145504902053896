import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { FIELD_OPTIONS_ENDPOINTS } from '../utils/fieldOptionsEndpoints';
import performApiRequest from '../utils/performApiRequest';
import { useAuthStatus } from '../hooks/useAuthStatus';

interface EndpointData {
  [key: string]: {
    data: any;
    lastFetched: number;
  };
}

interface DataContextValue {
  endpointData: EndpointData;
  fetchDataForEndpoint: (endpointKey: keyof typeof FIELD_OPTIONS_ENDPOINTS) => Promise<void>;
}

const DataContext = createContext<DataContextValue | undefined>(undefined);

export const DataProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [endpointData, setEndpointData] = useState<EndpointData>({});
  const userIsAuthenticated = useAuthStatus();

  const fetchDataForEndpoint = useCallback(async (endpointKey: keyof typeof FIELD_OPTIONS_ENDPOINTS) => {
    const now = new Date().getTime();
    const endpointInfo = endpointData[endpointKey];
    const endpoint = FIELD_OPTIONS_ENDPOINTS[endpointKey];

    if ((!endpointInfo || now - endpointInfo.lastFetched > 24 * 60 * 60 * 1000) && userIsAuthenticated) {
      try {
        const response = await performApiRequest(endpoint);
        const newData = await response.data;
        setEndpointData(prev => ({
          ...prev,
          [endpointKey]: {
            data: newData,
            lastFetched: now,
          },
        }));
      } catch (error) {
        console.error(`Failed to fetch data for ${endpointKey}:`, error);
      }
    }
  }, [endpointData, userIsAuthenticated]);

  const value = { endpointData, fetchDataForEndpoint };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export const useDataForEndpoint = <T,>(endpointKey: keyof typeof FIELD_OPTIONS_ENDPOINTS) => {
  const context = useContext(DataContext);
  if (context === undefined) {
    throw new Error('useDataForEndpoint must be used within a DataProvider');
  }

  useEffect(() => {
    context.fetchDataForEndpoint(endpointKey);
  }, [endpointKey, context]);

  return context.endpointData[endpointKey]?.data as T;
};