import 'dayjs/locale/es';
import Layout from '../../components/layOutWrapper/layOutWrapper';
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useAuthStatus } from '../../hooks/useAuthStatus';
import BlockScreen from '../../components/blockScreen/blockScreen';
import Loader from '../../elements/loader';
import performApiRequest from '../../utils/performApiRequest';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import DownloadButton from '../../elements/downloadButton';

export default function AlertReportRepositoryView () {
  return (
      <Layout sectionTitle="Reportes de alerta">
          <AlertsReportsRepository/>
      </Layout>
  )
}

const AlertsReportsRepository: React.FC = () => {
    const isAuthenticated = useAuthStatus();
    const [reports, setReports] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    // // eslint-disable-next-line no-unused-vars
    // const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    
    const fetchReports = useCallback(async () => {
        setIsLoading(true);
        try {
            const queryParams = new URLSearchParams({
                //   page: currentPage.toString(),
                limit: '10',
            });

            const endpoint = `/v1/alertReportsRetriever/getAlertReports?${queryParams.toString()}`;
            const requestOptions = {
                method: 'GET',
                'Content-Type': 'application/json',
            };
            if (isAuthenticated) {
                const response = await performApiRequest(endpoint, requestOptions);
                console.log(response.data);
                setReports(response.data.reports);
                setTotalPages(response.data.totalPages || 1);
            }
        } catch (error) {
            console.error('There was an error fetching the Travel Allowance requests:', error);
        } finally {
            setIsLoading(false);
        }
    }, [isAuthenticated]);

    const fetchActionsRef = useRef(fetchReports);

    useEffect(() => {
        fetchActionsRef.current = fetchReports;
    }, [fetchReports]);

    useEffect(() => {
        if (isAuthenticated) {
            fetchActionsRef.current();
        }
    }, [isAuthenticated]);

    if (!isAuthenticated) {
        return <BlockScreen/>
    }

    if (isLoading) {
        return <Loader/>
    }
    
    return (
        <div className="grid grid-cols-1 justify-center items-center p-4 mb-10">
            <div className="overflow-x-auto rounded mb-4">
                <h2 className="py-3 text-gray-700 text-md font-semibold mb-10">Reportes de alerta</h2>
                <h4 className="py-3 text-gray-700 text-md font-semibold mb-10">Presiona el nombre de uno para descargarlo.</h4> 
            </div>
            <ul className="justify-center text-center">
                { reports.map((report: any) => (
                    <li key={report.id}>
                        <NotificationImportantIcon/>
                        <DownloadButton fileName={report.file_name}/>
                        <span className="text-blue-800 ml-4 mb-5 justify-left"> Reporte {report.action_datetime} </span>
                    </li>
                ))}
            </ul>
            <div className="text-center text-black mt-10">
                Página 1 de {totalPages}
            </div>
        </div>
    )
}
