import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import performApiRequest from '../../utils/performApiRequest';
import { API_ENDPOINTS } from '../../utils/endpoints';
import 'dayjs/locale/es';
import { useToast } from '../../context/useToast';
import HighlightOff from '@mui/icons-material/HighlightOff';

interface UploadFormModalProps {
    onClose: () => void;
    actual_request_id?: number;
}

const getValidationSchema = () => {
    const fileSchema = 
      Yup.mixed().test('fileType', 'El archivo debe ser de tipo PDF o imagen.', (value) => {
        if (!value || typeof value !== 'object') return true;
        const file = value as File;
        const supportedFileTypes = ['application/pdf', 'image/jpeg', 'image/png'];
        return supportedFileTypes.includes(file.type);
      }).required('Debes adjuntar el archivo.').test('fileType', 'El archivo debe ser de tipo PDF o imagen.', (value) => {
        if (!value || typeof value !== 'object') return true;
        const file = value as File;
        const supportedFileTypes = ['application/pdf', 'image/jpeg', 'image/png'];
        return supportedFileTypes.includes(file.type);
    });
  
    return Yup.object().shape({
        file: fileSchema,
    });
  };
  

const UploadFileToExpenseReportRequest: React.FC<UploadFormModalProps> = ({ onClose, actual_request_id }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [filePreview, setFilePreview] = useState<string | null>(null);
    const { showSuccessToast, showErrorToast } = useToast();

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const selectedFile = files[0];
            setUploadedFile(selectedFile);
            formik.setFieldValue('file', selectedFile);
            const reader = new FileReader();
            reader.onload = () => {
                const result = reader.result;
                if (result && typeof result === 'string') {
                    setFilePreview(result);
                }
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const handleCancel = () => {
        setUploadedFile(null);
        setFilePreview(null);
        onClose();
    };

    const formik = useFormik({
        initialValues: {
            file: '',
        },
        validationSchema: getValidationSchema(),
        onSubmit: async (values: 
            {
                file: string | Blob
            }) => {
                const formData = new FormData();
                if (values.file && values.file instanceof File) {
                    formData.append('file', values.file);
                } else {
                    alert("Debes adjuntar un archivo.")
                }
                if (Number(actual_request_id) > 0 && actual_request_id !== undefined) {
                    formData.append('request_id', actual_request_id.toString());
                } else {
                    alert("Error. Refresca la página e inténtalo de nuevo.")
                }
                const requestOptions = {
                    method: 'PATCH',
                    data: formData
                };
                try {
                    const response = await performApiRequest(API_ENDPOINTS.addFileToExpenseReportRequest, requestOptions);
                    if (response.status === 201) {
                        showSuccessToast('Archivo subido exitosamente.');
                        onClose();
                        window.location.reload();
                    }
                } catch (error: any) {
                    showErrorToast(`Error: ${error.message}`);
                }
            }
        }
    );

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
            <div className="relative top-20 mx-auto p-5 border w-10/12 shadow-lg rounded-md bg-white px-10 py-10">
                <div className="cursor-pointer inline-block close" onClick={handleCancel}>
                    <div className="px-2 py-1">
                        <span><HighlightOff fontSize="large"/></span>
                    </div>
                </div>
                <div className="mt-3 text-center">
                    <div className="flex flex-col md:flex-row p-4 bg-white shadow rounded-lg">
                        <div className="w-full md:w-1/2 p-4">
                            <h2 className="text-gray-700 text-lg font-bold mb-4">Subir archivo</h2>
                                <input
                                    type="file"
                                    onChange={handleFileChange}
                                    accept="image/*, application/pdf"
                                />
                            {filePreview && (
                                    <img src={filePreview} alt="File Preview" className="mt-4 max-w-full h-auto" />
                            )}
                        </div>
                        <form className="bg-sectionBarBackgroundColor shadow-md rounded px-8 pt-6 pb-8 mb-4" encType="multipart/form-data" onSubmit={formik.handleSubmit}>
                            <button
                                className={`font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-blue-500 hover:bg-blue-700 text-white`}
                                type="submit"
                            >
                                Subir documento
                            </button>
                        </form>

                        </div>
                    </div>
                </div>
        </div>
    );
};

export default UploadFileToExpenseReportRequest;