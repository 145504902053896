import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

interface LoaderProps {
  message?: string;
}

const Loader: React.FC<LoaderProps> = ({ message }) => {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh' // or '100%' if it's within a container that defines its height
    }}>
      <div>
        <CircularProgress />
      </div>
      <div className={"text-md mb-5 mt-5"}>
        {message && <div className={"text-md"}>{message}</div>}  
      </div>
    </Box>
  );
}

export default Loader;