import BlockIcon from '@mui/icons-material/Block';

const BlockScreen = () => {
  // Calculate square size based on screen width, you may need to handle window resizing
  const squareSize = `w-[80vw] h-[30vw] max-w-md`; // Adjust max-width/height as needed

  return (
    <div className={`flex justify-center items-center bg-[#F5FCFF] h-6/12`}>
      <div className={`${squareSize} flex flex-col justify-center items-center bg-white rounded-lg shadow-lg h-6/12`}>
        <BlockIcon style={{ fontSize: 50, color: "red" }}/>
        <p className="text-[#5A5A5A] font-bold text-lg text-center my-5">Inicia sesión para tener acceso a esta vista.</p>
      </div>
    </div>
  );
};
// Create code for a block screen: a square with a BlockIcon in the middle and the texto below it 
export default BlockScreen;