export const reviewModalConfig = {
    resendAsFormCompleter: {
        title: "Reenviar la solicitud",
        message: "Vas a volver a enviar la solicitud. Asegúrate de tener los datos correctos esta vez.",
        actionName: "Reenviar",
        endpoint: '/v1/approvalController/resendRequestAsFormCompleter',
        isCommentMandatory: false,
    },
    markAsReceived: {
        title: "Marcar la solicitud como recibida",
        message: "Vas a marcar la solicitud como recibida, por lo que el solicitante no la podrá modificar.",
        actionName: "Marcar como recibida",
        endpoint: '/v1/approvalController/markRequestAsReceived',
        isCommentMandatory: false,
    },
    approveRequest: {
        title: "Aprobar la solicitud",
        message: "Vas a aprobar la solicitud. Agrega un comentario para el solicitante o el siguiente agente de la cadena.",
        actionName: "Aprobar",
        endpoint: '/v1/approvalController/approveRequest',
        isCommentMandatory: false,
    },
    sendBackRequest: {
        title: "Devolver solicitud",
        message: "Vas a devolver la solicitud al agente anterior de la cadena. Agrega un comentario de ser necesario.",
        actionName: "Devolver solicitud",
        endpoint: '/v1/approvalController/sendBackRequest',
        isCommentMandatory: true,
    },
    rejectRequest: {
        title: "Rechazar solicitud",
        message: "Vas a rechazar la solicitud. Agrega un comentario con la justificación.",
        actionName: "Rechazar",
        endpoint: '/v1/approvalController/rejectRequest',
        isCommentMandatory: true,
    },
    markRequestAsToBePaid: {
        title: "Validar cálculo de la solicitud.",
        message: "Vas a enviar la solicitud a Tesorería para su pago. Agrega un comentario con información importante de ser necesario.",
        actionName: "Enviar a Tesorería",
        endpoint: '/v1/approvalController/markRequestAsToBePaid',
        isCommentMandatory: false,
    },
    sendBackRequestFromAccounting: {
        title: "Devolver solicitud desde contabilidad",
        message: "Como usuario de Contabilidad puedes devolver la solicitud a un agente anterior de la cadena. Agrega un comentario de ser necesario.",
        actionName: "Enviar solicitud de vuelta",
        endpoint: '/v1/approvalController/sendBackRequestFromAccounting',
        isCommentMandatory: true,
    },
    rejectRequestFromAccounting: {
        title: "Rechazar solicitud desde contabilidad",
        message: "Vas a rechazar esta solicitud. Agrega un comentario con la justificación.",
        actionName: "Reject from Accounting",
        endpoint: '/v1/approvalController/rejectRequestFromAccounting',
        isCommentMandatory: true,
    },
    markRequestAsPaid: {
        title: "Registrar pago de la solicitud",
        message: "Vas a registrar que la solicitud fue pagada, finalizando su proceso. Agrega un comentario de ser necesario.",
        actionName: "Marcar solicitud como pagada",
        endpoint: '/v1/approvalController/markRequestAsPaid',
        isCommentMandatory: false,
    },
    rejectRequestFromTreasury: {
        title: "Rechazar solicitud",
        message: "Vas a rechazar esta solicitud en la etapa previa a su pago. Agrega un comentario con la justificación.",
        actionName: "Rechazar solicitud",
        endpoint: '/v1/approvalController/rejectRequestFromTreasury',
        isCommentMandatory: true
    },
};