import React from "react";
import { useMsal } from '@azure/msal-react';
import { useAuthStatus } from "../hooks/useAuthStatus";

const LoginButton: React.FC = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useAuthStatus();

  const handleLogin = async () => {
    try {
      if (accounts.length === 0) {
        instance.loginRedirect({ scopes: ["User.Read"] })
      }
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const handleLogout = () => {
    instance.logoutRedirect({
        postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URI,
    });
  };

  if (!isAuthenticated) {
    return (
      <button className="bg-blue-500 hover:bg-blue-700 text-sm text-white font-bold py-2 px-4 rounded-full" onClick={handleLogin}>Iniciar sesión</button>
    );
  } else {
    return (
      <button className="bg-blue-500 hover:bg-blue-700 text-white text-sm font-bold py-2 px-4 rounded-full" onClick={handleLogout}>Cerrar sesión</button>
    );   
  }

};

export default LoginButton;